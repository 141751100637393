<template>
  <div class="flex justify-content-between flex-wrap mb-3">
    <div class="flex align-items-center justify-content-center">
      <h3>
        {{ t("user.exports.title") }}
      </h3>
    </div>
  </div>
  <div>
    <SpmTable
      name="ClientExportsData"
      :table-columns="clientExportColumns"
      :id-shop="idShop"
      :initial-sorting="initialSorting"
      :paginator="true"
      index="id_exports_data"
      :persistent-filters="persistentFilters"
      :reload-data-table="false"
    >
      <template #data="slotProps">
        <div v-if="slotProps.data.data === '1'">
          <Button
            :key="slotProps.data.id_exports_data"
            icon="fa-regular fa-solid fa-download"
            class="p-button-text"
            :label="t('user.exports.columns.linkToDownload')"
            :loading="currentDownloadExportId === slotProps.data.id_exports_data"
            :disabled="currentDownloadExportId !== 0"
            @click="downloadExportFile(slotProps.data.id_exports_data)"
          />
        </div>
        <div v-else>
          {{ t('user.exports.columns.notAvailable') }}
        </div>
      </template>
      <template #name="slotProps">
        {{ slotProps.data.name }} <Tag
          class="small-tag"
          :severity="slotProps.data.user_type === 'admin' ? 'success' : 'info'"
          :value="slotProps.data.user_type"
        />
      </template>
    </SpmTable>
  </div>
</template>

<script lang="ts">
import SpmTable from '@/components/table/SpmTable.vue';
import {
  SpmTableColumns, SpmTableFilter, SpmTableSort,
} from '@/types';
import {
  defineComponent, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { UserState } from '@/composables/User';
import { OperatorType } from '@/types/generated-types/graphql';
import Tag from 'primevue/tag';
import axios from 'axios';
import { showToast } from '@/helpers';
import Button from 'primevue/button';

export default defineComponent({
  name: 'UserExports',

  components: {
    SpmTable,
    Tag,
    Button,
  },

  setup() {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const currentDownloadExportId = ref(0);
    const clientExportColumns: SpmTableColumns[] = [
      {
        field: 'id_exports_data',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
        prefix: 'ced',
      },
      {
        field: 'date_created',
        header: t('user.exports.columns.dateCreated'),
        sortable: false,
        filterable: false,
        editable: true,
        style: '',
        type: 'text',
        prefix: 'ced',
      },
      {
        field: 'name',
        header: t('user.exports.columns.name'),
        sortable: false,
        filterable: false,
        editable: true,
        type: 'text',
        prefix: 'su',
        style: UserState.user.userType !== 'admin' ? 'display:none' : '',
      },
      {
        field: 'user_type',
        header: t('user.exports.columns.userType'),
        sortable: false,
        filterable: false,
        editable: true,
        style: 'display:none',
        type: 'text',
      },
      {
        field: 'file_name',
        header: t('user.exports.columns.fileName'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'ced',
      },
      {
        field: 'data',
        header: t('user.exports.columns.linkToDownload'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
    ];

    const persistentFilters: SpmTableFilter[] = [
      {
        field: 'user_type',
        value: UserState.user.userType,
        operator: OperatorType.Equals,
      },
      {
        field: 'id_user',
        value: UserState.user.id,
        operator: OperatorType.Equals,
      },
    ];

    const initialSorting: SpmTableSort[] = [
      {
        field: 'date_created',
        type: 'DESC',
      },
    ];

    const getFileNameFromHeader = (response: any) => {
      const contentDisposition = response.headers['content-disposition'];
      const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
      return filenameMatch && filenameMatch.length > 1 ? filenameMatch[1] : 'myDataExport.csv';
    };

    const downloadExportFile = (idExportsData: number) => {
      showToast('info', 'user.exports.download.preparing');
      currentDownloadExportId.value = idExportsData;
      axios.create({ baseURL: '/api' }).get(`/download-file/${idExportsData}`, { responseType: 'blob' }).then((response: any) => {
        // Create a Blob object from the response data
        const blob = new Blob([response.data]);

        // Create a temporary anchor element
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);

        const filename = getFileNameFromHeader(response);

        // Set the download attribute and filename
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Remove the anchor element from the DOM
        document.body.removeChild(downloadLink);
        showToast('success', t('user.exports.download.complete'));
        currentDownloadExportId.value = 0;
      }).catch((err: any) => {
        showToast('error', t('user.exports.download.error'));
        currentDownloadExportId.value = 0;
      });
    };

    return {
      t,
      clientExportColumns,
      idShop,
      initialSorting,
      persistentFilters,
      downloadExportFile,
      currentDownloadExportId,
    };
  },
});
</script>

<style>
.small-tag .p-tag-value {
  line-height: 1 !important;
}
</style>
