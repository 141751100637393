
import {
  defineComponent, onBeforeMount, onMounted, onUnmounted, ref, watch,
} from 'vue';
import Checkbox from 'primevue/checkbox';
import { useI18n } from 'vue-i18n';

import {
  getIsAuthenticated, Login, Me, setPermissionsString, SwitchActiveShop, UserState,
} from '@/composables/User';
import { useStore } from '@/store';
import { showToastError } from '@/helpers';
import { NavigationFailure, useRouter } from 'vue-router';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';

export default defineComponent({
  name: 'Login',
  components: {
    BaseTextInput,
    Checkbox,
    SpmButton,
  },

  setup() {
    const checked = ref(false);
    const email = ref('');
    const password = ref('');
    const isEmailNotValid = ref(false);
    const isPasswordNotValid = ref(false);
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();

    const checkForm = async (): Promise<void|NavigationFailure|undefined|null> => {
      store.commit('general/showTheSpinner');
      isEmailNotValid.value = !email.value;
      isPasswordNotValid.value = !password.value;

      if (isEmailNotValid.value || isPasswordNotValid.value) {
        store.commit('general/hideTheSpinner');
        return null;
      }

      try {
        await Login({ username: email.value, password: password.value });
        await Me(false);

        if (UserState.user.shops.length === 1) {
          await SwitchActiveShop(UserState.user.shops[0].id);
          store.commit('liveEditor/resetWebsiteContents');
          store.commit('general/hideTheSpinner');
          return router.push({ name: 'home' });
        }

        store.commit('general/hideTheSpinner');
        return router.push({ name: 'choose-shop' });
      } catch (error) {
        store.commit('general/hideTheSpinner');
        return showToastError(t('user.wrongCredentials'));
      }
    };

    watch(email, (value: string) => {
      isEmailNotValid.value = !value;
    });

    watch(password, (value: string) => {
      isPasswordNotValid.value = !value;
    });

    onMounted(() => {
      document.body.classList.add('no_expand');
    });

    onBeforeMount(async () => {
      const isAuthenticated = getIsAuthenticated();
      if (isAuthenticated) {
        return router.push({ name: 'home' });
      }
      document.body.classList.add('no_expand');
      return true;
    });

    onUnmounted(() => {
      document.body.classList.remove('no_expand');
    });

    return {
      checked,
      email,
      password,
      t,
      checkForm,
    };
  },
});
