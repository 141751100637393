
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { showToastSuccess, showToastError } from '@/helpers';
import { insertShopsListsActions } from '@/composables/mes-listes/ListCustomers';
import {
  defineComponent, PropType, ref, SetupContext,
} from 'vue';
import { getShopsLists, listManageKey } from '@/composables/mes-listes/ListsManage';
import { ListResult } from '@/composables/GraphQL';
import {
  ShopsLists,
  ShopsListsUpdateInputItem,
} from '@/types/generated-types/graphql';
import { useI18n } from 'vue-i18n';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { UpdateShopsLists } from '@/composables/shop/ShopsLists';
import MultiSelect from 'primevue/multiselect';
import { useToast } from 'primevue/usetoast';

interface UnsubItem {
  code: number;
  icon: string;
  name: string;
}

enum actionTypes {
  export = 'export',
  copy = 'copy',
  move = 'move',
  create = 'newlist',
  duplicate = 'duplicate',
  unsub = 'unsub_newsletter',
  delete = 'delete',
  deleteAll = 'delete_from_all_lists',
  rename = 'rename',
  import = 'import',
  custom = 'custom'
}

export default defineComponent({
  name: 'ActionModal',

  components: {
    Dialog,
    Button,
    Dropdown,
    InputText,
    MultiSelect,
  },

  props: {
    message: {
      type: String,
      required: true,
    },

    header: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },

    acceptLabel: {
      type: String,
      required: true,
    },

    rejectLabel: {
      type: String,
      required: true,
    },

    actionSelection: {
      type: String || null,
      required: false,
      default: null,
    },

    rows: {
      type: Array as PropType<number[]>,
      required: true,
      default: () => [],
    },

    idShop: {
      type: Number,
      required: true,
    },

    showCustomDialog: {
      type: Boolean,
      required: true,
      default: false,
    },

    actionType: {
      type: String,
      required: true,
    },

    settings: {
      type: String,
      required: true,
    },

    idShopList: {
      type: String || null,
      required: false,
      default: null,
    },

    unselectedRows: {
      type: Array as PropType<number[]>,
      required: true,
      default: () => [],
    },

    idCustomer: {
      type: Number || null,
      required: false,
      default: null,
    },

    listName: {
      type: String,
      required: false,
      default: '',
    },

    customFunction: {
      type: Function,
      required: false,
      default: () => ({}),
    },
  },

  emits: [
    'close-action-modal',
    'reload-data',
  ],

  setup(props: { message: string; header: string; icon: string; acceptLabel: string; rejectLabel: string;
    actionSelection: string; rows: number[]; idShop: number; showCustomDialog: boolean; actionType: string;
    settings: string; idShopList: string| null; unselectedRows: number[]; idCustomer: number| null; listName: string;
    customFunction: Function;}, context: SetupContext) {
    const dialogVisible = ref<boolean>(props.showCustomDialog);
    const lists = ref<ShopsLists[]>();
    const { t } = useI18n();
    const listsSelection = ref();
    const newListName = ref(props.actionType === actionTypes.duplicate ? (t('myLists.manage.actions.cloneText') + props.listName) : '');
    const modalWidth: { width: string } = props.actionType === 'unsub_newsletter' ? { width: '46vw' } : { width: '25vw' };
    const headerString = ref(props.header);
    const selectedTypes = ref();
    const toast = useToast();

    if (props.actionType === actionTypes.duplicate) {
      headerString.value = `${props.header} "${props.listName}"`;
      newListName.value = t('myLists.manage.actions.cloneText') + props.listName;
    } else if (props.actionType === actionTypes.rename) {
      newListName.value = props.listName ?? '';
    }

    const rename = async () => {
      const shopListId = Number(props.idShopList);
      if (Number.isNaN(shopListId)) await showToastError('GENERIC_ERROR');

      const updatedShopsList: ShopsListsUpdateInputItem = {
        id_shop_list: shopListId,
        name: newListName.value,
      };

      const result = await UpdateShopsLists([updatedShopsList]);

      if (result.err === '') {
        await showToastSuccess(t('myLists.manage.actions.listRename.success'));
      } else {
        await showToastError(t('myLists.manage.actions.listRename.error'));
      }
      dialogVisible.value = false;
      // context.emit('reload-data');
      listManageKey.value += 1;
      context.emit('close-action-modal', listsSelection);
    };

    const deleteList = async () => {
      const shopListId = Number(props.idShopList);
      if (Number.isNaN(shopListId)) await showToastError('GENERIC_ERROR');

      const updatedShopsList: ShopsListsUpdateInputItem = {
        id_shop_list: shopListId,
        active: 0,
      };

      const result = await UpdateShopsLists([updatedShopsList]);

      if (result.err === '') {
        await showToastSuccess(t('myLists.manage.actions.listDelete.success'));
      } else {
        await showToastError(t('myLists.manage.actions.listDelete.error'));
      }
      dialogVisible.value = false;
      // context.emit('reload-data');
      listManageKey.value += 1;
      context.emit('close-action-modal', listsSelection);
    };

    const getParamsJson = () => {
      let unsubType = 0;
      if (selectedTypes.value.length > 0) {
        selectedTypes.value.forEach((options: {code: number; icon: string; name: string }) => {
          // eslint-disable-next-line no-bitwise
          unsubType |= options.code;
        });
      }
      const params: { typeUnsub: string } = { typeUnsub: unsubType.toString() };
      return JSON.stringify(params);
    };

    const executeAction = async (item: { event: Event; value: string }) => {
      if (props.actionType === actionTypes.rename) {
        return rename();
      }

      if (props.actionType === actionTypes.custom) {
        props.customFunction(props.rows);
        context.emit('close-action-modal', listsSelection);
        return true;
      }

      if (props.actionSelection !== 'unselect' && ((props.rows && props.rows.length > 0) || props.idShopList)) {
        let paramsString: string| null = null;

        if (props.actionType === 'unsub_newsletter' && selectedTypes.value.length > 0) {
          paramsString = getParamsJson();
        }

        const concernedIds: number[] = props.actionSelection === 'all' ? props.unselectedRows : props.rows;

        const from: string| null = props.idShopList ?? null;

        let to = null;

        if (props.actionType === actionTypes.copy
          || props.actionType === actionTypes.move
          || props.actionType === actionTypes.import) {
          to = listsSelection.value;
        }

        if (props.actionType === actionTypes.delete && concernedIds.length === 0) {
          return deleteList();
        }

        const groupAction = props.actionSelection === 'unselect' ? null : props.actionSelection;

        const result = await insertShopsListsActions(
          groupAction,
          props.idShop,
          newListName.value,
          props.actionType,
          concernedIds,
          from,
          to,
          props.settings,
          paramsString,
        );

        if (result.err === '') {
          toast.add({
            severity: 'success',
            summary: t('success'),
            detail: t('myLists.manage.bulkActions.successMessage'),
            life: 4000,
          });
        } else {
          await showToastError('GENERIC_ERROR');
        }
      }
      dialogVisible.value = false;
      context.emit('close-action-modal', listsSelection);
    };

    const executeCustomerAction = async (item: { event: Event; value: string }) => {
      let paramsString: string| null = null;

      const from: string| null = props.idShopList ?? null;

      if (props.actionType === 'unsub_newsletter' && selectedTypes.value.length > 0) {
        paramsString = getParamsJson();
      }

      let to = null;

      if (props.actionType === actionTypes.copy
        || props.actionType === actionTypes.move
        || props.actionType === actionTypes.import) {
        to = listsSelection.value;
      }

      if (props.idCustomer) {
        const result = await insertShopsListsActions(
          null,
          props.idShop,
          newListName.value ?? null,
          props.actionType,
          [props.idCustomer],
          from,
          to,
          props.settings,
          paramsString,
        );

        if (result.err === '') {
          toast.add({
            severity: 'success',
            summary: t('success'),
            detail: t('myLists.manage.bulkActions.successMessage'),
            life: 4000,
          });
        } else {
          await showToastError('GENERIC_ERROR');
        }
      } else {
        await showToastError('GENERIC_ERROR');
      }

      dialogVisible.value = false;
      context.emit('close-action-modal', listsSelection);
    };

    const updateDialogVisibility = () => {
      dialogVisible.value = false;
      context.emit('close-action-modal', []);
    };

    const getShopsListsList = async () => {
      const fields: string[] = [
        'id_shop_list', 'name', 'type',
        'total_contacts', 'total_sends', 'total_newsletters_viewed', 'total_newsletters_clicked',
        'total_subscribers', 'total_unsubscribeds',
        'total_sends_soft_bounce', 'total_sends_hard_bounce', 'total_sends_spam',
      ];
      const result: ListResult<ShopsLists> = await getShopsLists(props.idShop, fields);
      lists.value = result.items ?? [];
      listsSelection.value = lists.value[0].id_shop_list;
    };

    const items: UnsubItem[] = [
      {
        code: 1,
        icon: 'fas fa-cog fa-fw',
        name: t('myLists.unsub.icons.autoEmail'),
      },
      {
        code: 2,
        icon: 'fa fa-mobile-alt fa-fw',
        name: t('myLists.unsub.icons.autoSms'),
      },
      {
        code: 4,
        icon: 'fas fa-envelope fa-fw',
        name: t('myLists.unsub.icons.newsletter'),
      },
      {
        code: 8,
        icon: 'fa fa-mobile-alt fa-fw',
        name: t('myLists.unsub.icons.bulkSms'),
      },
      {
        code: 16,
        icon: 'far fa-times-circle',
        name: t('myLists.unsub.icons.noSolicitation'),
      },
    ];

    const handleSelected = (options: {code: number; icon: string; name: string }[]) => {
      options.forEach((option) => {
        if (option.code === 16) {
          selectedTypes.value = [];
          selectedTypes.value.push(...items);
        }
      });
    };

    return {
      t,
      executeAction,
      updateDialogVisibility,
      dialogVisible,
      lists,
      getShopsListsList,
      listsSelection,
      newListName,
      items,
      selectedTypes,
      modalWidth,
      executeCustomerAction,
      headerString,
      handleSelected,
    };
  },
});
