<template>
  <div class="configuration-wrapper">
    <BaseTextInput
      v-model="listName"
      input-type="text"
      field-id="listName"
      label="myLists.manage.nameList"
      input-style="width: 100%;"
      :disable="listId > 0"
      :errors="settingPanelValidationErrors"
    />
    <div class="field-wrapper">
      <ButtonGroup
        :key="refreshKey"
        v-model="settingsFields.keepListUpdated"
        :options="keepListUpdatedOptions"
        tooltip-desc="myLists.importContacts.segmentation.labelTips"
        title="myLists.importContacts.segmentation.label"
        :allow-empty="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n';
import {
  computed,
  defineComponent, onMounted, ref, watch,
} from 'vue';
import ButtonGroup from '@/components/mes-lists/fields/ButtonGroup.vue';
import { store } from '@/store';
import BaseTextInput from '@/components/fields/BaseTextInput.vue';

export default defineComponent({
  name: 'SettingsPanelSegmentations',
  components: { BaseTextInput, ButtonGroup },

  props: {
    settingPanelValidationErrors: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  setup() {
    const { t } = useI18n();
    /* Fields options */
    const keepListUpdatedOptions = [
      {
        label: t('yes'),
        value: true,
      },
      {
        label: t('no'),
        value: false,
      },
    ];
    const listName = computed(() => store.getters['listImportEditor/getListName']);
    const settingsFields = computed(() => store.getters['listImportEditor/getSettingsData']);
    const listId = computed(() => store.getters['listImportEditor/getIdShopList']);
    const refreshKey = ref(0);
    watch([listName], () => {
      store.commit('listImportEditor/setListName', listName.value);
    });

    onMounted(() => {
      refreshKey.value += 1;
    });

    return {
      listName,
      settingsFields,
      keepListUpdatedOptions,
      listId,
      refreshKey,
    };
  },
});
</script>

<style lang="scss">
</style>
