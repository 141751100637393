
import { useI18n } from 'vue-i18n';
import SpmTable from '@/components/table/SpmTable.vue';
import {
  DataExplorerDataType,
  ListType,
  SpmTableColumns, SpmTableFilter, SpmTableFilterOption,
} from '@/types';
import {
  defineComponent, onBeforeMount, onMounted, Ref, ref,
} from 'vue';
import Button from 'primevue/button';
import { UserState } from '@/composables/User';
import InlineMessage from 'primevue/inlinemessage';
import {
  OperatorType, ShopsCustomers, ShopsLists, ShopsListsImport,
} from '@/types/generated-types/graphql';
import SearchCustomers from '@/components/mes-lists/SearchCustomers.vue';
import ListsManageActions from '@/components/mes-lists/ListsManageActions.vue';
import { store } from '@/store';
import ImportListCustomers from '@/views/mes-listes/ImportListCustomers.vue';
import {
  getShopsListImportRecords,
  getShopsListRecord,
} from '@/composables/mes-listes/ListsManage';
import { GetResult, ListResult } from '@/composables/GraphQL';
import { newsletterTypeEnum } from '@/composables/shop/ShopsLists';
import { getShopCustomerLanguages } from '@/types/country-language-options';
import { MenuItemCommandEvent } from 'primevue/menuitem';
import Tooltip from 'primevue/tooltip';
import { showToastError } from '@/helpers';

export default defineComponent({
  name: 'ListsCustomers',

  components: {
    ImportListCustomers,
    SpmTable,
    Button,
    InlineMessage,
    SearchCustomers,
    ListsManageActions,
  },

  directives: {
    Tooltip,
  },

  props: {

    id: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      require: false,
      default: '',
    },
  },

  setup(props: { id: string; type: string}) {
    const { t } = useI18n();
    const childComponentRef = ref();
    const idShop = UserState?.activeShop?.id ?? 0;
    const importListName = ref('');
    const isListSegmented = ref(false);
    const initButtons = ref(false);
    const countRequestListImport = ref(0);
    const pluralization = ref(0);
    let particular = false;
    let columns: SpmTableColumns[] = [];
    const search = ref(false);
    const idShopList = ref(props.id);
    const displayImport = ref(false);
    const shopsImportList: Ref<ListResult<ShopsListsImport>> = ref({
      items: [],
      total: 0,
      err: '',
    });

    const shopsList: Ref<GetResult<ShopsLists>> = ref({
      item: {
        id_shop_list: 0,
        id_shop: 0,
        name: '',
        total_contacts: 0,
        total_subscribers: 0,
        total_unsubscribeds: 0,
        total_sends: 0,
        total_sends_soft_bounce: 0,
        total_sends_hard_bounce: 0,
        total_sends_spam: 0,
        total_newsletters_viewed: 0,
        total_newsletters_clicked: 0,
        date_add: null,
        date_modification: null,
        active: 0,
        valid: false,
        whitelist: false,
      },

      err: '',
    });

    if (props.type) {
      idShopList.value = props.type;
    }
    let actionList = [];

    if (props.type) {
      particular = true;
      actionList = [
        {
          code: 'copy',
          label: t('myLists.manage.bulkActions.copy.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.copy.text'),
              t('myLists.manage.bulkActions.copy.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.copy.acceptLabel'),
              t('myLists.manage.bulkActions.copy.rejectLabel'),
              'copy',
              null,
            );
          },
        },
        {
          code: 'create',
          label: t('myLists.manage.bulkActions.create.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.create.text'),
              t('myLists.manage.bulkActions.create.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.create.acceptLabel'),
              t('myLists.manage.bulkActions.create.rejectLabel'),
              'newlist',
              null,
            );
          },
        },
        {
          code: 'unsub',
          label: t('myLists.manage.bulkActions.unsub.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.unsub.textactionList'),
              t('myLists.manage.bulkActions.unsub.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.unsub.acceptLabel'),
              t('myLists.manage.bulkActions.unsub.rejectLabel'),
              'unsub_newsletter',
              null,
            );
          },
        },
      ];
    } else {
      actionList = [
        {
          code: 'copy',
          label: t('myLists.manage.bulkActions.copy.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.copy.text'),
              t('myLists.manage.bulkActions.copy.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.copy.acceptLabel'),
              t('myLists.manage.bulkActions.copy.rejectLabel'),
              'copy',
              null,
            );
          },
        },
        {
          code: 'move',
          label: t('myLists.manage.bulkActions.move.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.move.text'),
              t('myLists.manage.bulkActions.move.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.move.acceptLabel'),
              t('myLists.manage.bulkActions.move.rejectLabel'),
              'move',
              null,
            );
          },
        },
        {
          code: 'create',
          label: t('myLists.manage.bulkActions.create.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.create.text'),
              t('myLists.manage.bulkActions.create.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.create.acceptLabel'),
              t('myLists.manage.bulkActions.create.rejectLabel'),
              'newlist',
              null,
            );
          },
        },
        {
          code: 'unsub',
          label: t('myLists.manage.bulkActions.unsub.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.unsub.text'),
              t('myLists.manage.bulkActions.unsub.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.unsub.acceptLabel'),
              t('myLists.manage.bulkActions.unsub.rejectLabel'),
              'unsub_newsletter',
              null,
            );
          },
        },
        {
          code: 'delete',
          label: t('myLists.manage.bulkActions.delete.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.delete.text'),
              t('myLists.manage.bulkActions.delete.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.delete.acceptLabel'),
              t('myLists.manage.bulkActions.delete.rejectLabel'),
              'delete',
              null,
            );
          },
        },
        {
          code: 'deleteAll',
          label: t('myLists.manage.bulkActions.deleteFromAll.menuItem'),
          command: (event: MenuItemCommandEvent) => {
            childComponentRef.value.toggleDialog(
              idShopList.value,
              t('myLists.manage.bulkActions.deleteFromAll.text'),
              t('myLists.manage.bulkActions.deleteFromAll.formTitle'),
              'far fa-info-circle',
              t('myLists.manage.bulkActions.deleteFromAll.acceptLabel'),
              t('myLists.manage.bulkActions.deleteFromAll.rejectLabel'),
              'delete_from_all_lists',
              null,
            );
          },
        },
      ];
    }

    const updateListBtnIsLoading = ref(false);
    const importListType = ref('');

    const showImportListCustomers = async () => {
      if (isListSegmented.value) {
        updateListBtnIsLoading.value = true;
        const segmentConfig = await store.dispatch('segmentationsEditor/getConfiguration', {
          idShopList: shopsList.value.item?.id_shop_list,
          idShop,
        });
        if (segmentConfig) {
          await store.dispatch('listImportEditor/importCustomersAction', {
            idShopList: shopsList.value.item?.id_shop_list,
            typeImport: 'segmentation',
            listName: shopsList.value.item?.name,
            listType: shopsList.value.item?.type ?? ''
          });
          store.commit('listImportEditor/setKeepUpdated', segmentConfig.stay_updated);
        } else {
          showToastError(t('errorMessages.GENERIC_ERROR'));
        }
        updateListBtnIsLoading.value = false;
      } else {
        idShopList.value = shopsList.value.item ? shopsList.value.item.id_shop_list.toString(10) : '';
        importListName.value = shopsList.value.item ? shopsList.value.item?.name : '';
        importListType.value = shopsList.value.item?.type ?? '';
        displayImport.value = true;
      }
    };

    const toggleDialogFunc = (listId: string, message: string, header: string, icon: string, acceptLabel: string, rejectLabel: string,
      actionType: string, customerId: number|null) => {
      childComponentRef.value.toggleDialog(
        listId,
        message,
        header,
        icon,
        acceptLabel,
        rejectLabel,
        actionType,
        customerId,
      );
    };

    const toggleExportFunc = () => {
      childComponentRef.value.exportConfirmDialog();
    };

    const persistentFilters = ref<SpmTableFilter[]>([

      {
        field: 'sc.id_shop',
        operator: OperatorType.Equals,
        value: idShop,
      },
    ]);

    if (!particular) {
      persistentFilters.value = [...persistentFilters.value,
        {
          field: 'slc.id_shop_list',
          operator: OperatorType.Equals,
          value: props.id,
        },
      ];
    }

    const originalFilters = persistentFilters.value;

    const execute = (values: any) => {
      persistentFilters.value = values.value;
    };

    const changeSearchValues = () => {
      search.value = false;
    };

    const newsletterOptions: SpmTableFilterOption[] = Object.values(newsletterTypeEnum)
      .map((key: string) => ({ value: key, label: t(`myLists.manage.customers.optin.${key}`) }));

    const normalColumns: SpmTableColumns[] = [
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date_creation',
        header: t('myLists.manage.customers.headers.addDate'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:11%',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'sc',
      },
      {
        field: 'date_modification',
        header: t('myLists.manage.customers.headers.modifDate'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:11%',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: t('myLists.manage.customers.headers.firstname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'last_name',
        header: t('myLists.manage.customers.headers.lastname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:15%',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myLists.manage.customers.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'width:20%',
        type: 'data-explorer-link',
        prefix: 'sc',
        dataExplorerOptions: {
          id: 'id_shop_customer',
          type: DataExplorerDataType.CONTACT_FILE
        },
      },
      {
        field: 'country',
        header: t('myLists.manage.customers.headers.country'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'text-align: center;vertical-align: middle;width:9%',
        type: 'flag',
        prefix: 'sc',
        filterSettings: { type: 'country', hideFilterMenu: true },
      },
      {
        field: 'lang',
        header: t('myLists.manage.customers.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'text-align: center;vertical-align: middle;width:9%',
        type: 'language',
        prefix: 'sc',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'newsletter',
        header: t('myLists.manage.customers.headers.newsletter'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'text-align: center;vertical-align: middle;width:10%',
        type: 'text',
        prefix: 'sc',
        filterSettings: { type: 'multiSelect', options: newsletterOptions, hideFilterMenu: true },
      },
      {
        field: 'unsub_type',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
        prefix: 'sc',
        hidden: true,
      },
      {
        field: 'actions',
        header: t('myLists.manage.actions.placeholder'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        custom: true,
      },
    ];

    const rejectTypes = [{ value: 'hard-bounce', label: 'hard-bounce' }, { value: 'soft-bounce', label: 'soft-bounce' },
      { value: 'spam', label: 'spam' }, { value: 'unsub', label: 'unsub' }];

    const bouncesColumns: SpmTableColumns[] = [
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date',
        header: t('myLists.manage.customers.headers.date'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
        prefix: 'er',
      },
      {
        field: 'rejectType',
        header: t('myLists.manage.customers.headers.reject_type'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'dropdown', options: rejectTypes },
        prefix: 'er',
      },
      {
        field: 'last_name',
        header: t('myLists.manage.customers.headers.lastname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: t('myLists.manage.customers.headers.firstname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myLists.manage.customers.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'country',
        header: t('myLists.manage.customers.headers.country'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'flag',
        filterSettings: { type: 'country' },
      },
      {
        field: 'lang',
        header: t('myLists.manage.customers.headers.lang'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'reject_message',
        header: t('myLists.manage.customers.headers.reject_message'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'actions',
        header: t('myLists.manage.actions.placeholder'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        custom: true,
      },
    ];

    const spamsColumns: SpmTableColumns[] = [
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date',
        header: t('myLists.manage.customers.headers.date'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'rejectType',
        header: t('myLists.manage.customers.headers.rejectType'),
        sortable: false,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'dropdown', options: rejectTypes },
        prefix: 'er',
      },
      {
        field: 'last_name',
        header: t('myLists.manage.customers.headers.lastname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: t('myLists.manage.customers.headers.firstname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myLists.manage.customers.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'country',
        header: t('myLists.manage.customers.headers.country'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'flag',
        prefix: 'sc',
        filterSettings: { type: 'country' },
      },
      {
        field: 'lang',
        header: t('myLists.manage.customers.headers.lang'),
        sortable: true,
        filterable: false,
        editable: true,
        style: '',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'reject_message',
        header: t('myLists.manage.customers.headers.rejectMessage'),
        sortable: true,
        filterable: true,
        editable: false,
        style: 'text-align: center;vertical-align: middle;',
        type: 'text',
      },
      {
        field: 'actions',
        header: t('myLists.manage.actions.placeholder'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        custom: true,
      },
    ];

    const retargetingColumns: SpmTableColumns[] = [
      {
        field: 'id_shop_customer',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'date_modification',
        header: t('myLists.manage.customers.headers.modifDate'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        filterSettings: { type: 'date', hideFilterMenu: true },
      },
      {
        field: 'unsub_type',
        header: t('myLists.manage.customers.headers.unsubType'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'unsub',
      },
      {
        field: 'last_name',
        header: t('myLists.manage.customers.headers.lastname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'first_name',
        header: t('myLists.manage.customers.headers.firstname'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'email',
        header: t('myLists.manage.customers.headers.email'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'text',
        prefix: 'sc',
      },
      {
        field: 'country',
        header: t('myLists.manage.customers.headers.country'),
        sortable: true,
        filterable: true,
        editable: false,
        style: '',
        type: 'flag',
        prefix: 'sc',
        filterSettings: { type: 'country' },
      },
      {
        field: 'lang',
        header: t('myLists.manage.customers.headers.lang'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'language',
        filterSettings: { type: 'language', options: getShopCustomerLanguages, hideFilterMenu: true },
      },
      {
        field: 'actions',
        header: t('myLists.manage.actions.placeholder'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        custom: true,
      },
    ];

    switch (props.type) {
      case 'bounces':
        columns = bouncesColumns;
        importListName.value = t('myLists.manage.listNames.bounces');
        break;
      case 'spams':
        columns = spamsColumns;
        importListName.value = t('myLists.manage.listNames.spams');
        break;
      case 'unsub':
        importListName.value = t('myLists.manage.listNames.unsub');
        columns = retargetingColumns;
        break;
      case 'sync':
        importListName.value = t('myLists.manage.listNames.sync');
        columns = retargetingColumns;
        break;
      case 'newsletter':
        importListName.value = t('myLists.manage.listNames.newsletter');
        columns = retargetingColumns;
        break;
      case 'retargeting':
        importListName.value = t('myLists.manage.listNames.retargeting');
        columns = retargetingColumns;
        break;
      default:
        columns = normalColumns;
        break;
    }

    onMounted(async () => {
      shopsImportList.value = await getShopsListImportRecords(Number(props.id));
      shopsList.value = await getShopsListRecord(Number(props.id), ['id_shop_list', 'name', 'type']);
      isListSegmented.value = shopsList.value.item?.type === ListType.SEGMENT;
      if (!shopsImportList.value.err) {
        countRequestListImport.value = shopsImportList.value.total;
      }
      importListName.value = shopsList.value.item ? shopsList.value.item?.name : '';
      initButtons.value = true;
    });

    onBeforeMount(async () => {
      await getShopCustomerLanguages();
      initButtons.value = false;
    });

    return {
      columns,
      t,
      isListSegmented,
      countRequestListImport,
      pluralization,
      importListName,
      particular,
      persistentFilters,
      originalFilters,
      execute,
      idShopList,
      changeSearchValues,
      idShop,
      actionList,
      childComponentRef,
      toggleDialogFunc,
      toggleExportFunc,
      showImportListCustomers,
      displayImport,
      updateListBtnIsLoading,
      importListType,
      initButtons,
    };
  },

});
