import axios from 'axios';

export function determineDelimiter(rows: string[]): string {
  const separators: string[] = [',', ';', '\t', '|', ':'];
  const delimiterCounts: Record<string, number> = {};

  separators.forEach((separator) => {
    delimiterCounts[separator] = 0;
  });

  rows.forEach((row) => {
    separators.forEach((separator) => {
      const count = (row.match(new RegExp(`\\${separator}`, 'g')) || []).length;
      delimiterCounts[separator] += count;
    });
  });

  // Trouver le délimiteur le plus fréquent
  let mostCommonDelimiter = ',';
  let maxCount: number = delimiterCounts[','];

  Object.keys(delimiterCounts).forEach((separator) => {
    if (delimiterCounts[separator] > maxCount) {
      mostCommonDelimiter = separator;
      maxCount = delimiterCounts[separator];
    }
  });

  return mostCommonDelimiter;
}

export function addUrlParameter(parameter: string, value: string) {
  // Ajouter un paramètre à l'URL afin de pouvoir charger gleap via url
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.set(parameter, value); // Exemple de paramètre à ajouter
  window.history.replaceState({}, '', `${url.pathname}?${params.toString()}`);
}

export function parseCsv<T>(csv: string, delimiter: string) {
  return 'ok';
}

export function triggerBlobDownload(data: Blob, filename: string) {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

export function sendMessageToSlack(channelKey: string, promptText: string, configData: any) {
  try {
    axios.post(
      `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/monitoring`,
      {
        channelKey,
        promptText,
        configData,
      },
    );
    // eslint-disable-next-line no-empty
  } catch (error) {
  }
}

export function removeAccents(str?: string): string {
  if (!str) return '';
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
