import {
  ComponentNameEnum, BaseFieldConfig, ListSelectionTypeEnum, StepperSignEnum, StringMap, SpmTableFilter,
} from '@/types';
import { UserState } from '@/composables/User';
import { OperatorType } from '@/types/generated-types/graphql';

const typesWithPeriodField: ListSelectionTypeEnum[] = [
  ListSelectionTypeEnum.CROSSSELLING,
  ListSelectionTypeEnum.CATALOG_BESTSELLERS,
  ListSelectionTypeEnum.CATEGORIES_BESTSELLERS,
  ListSelectionTypeEnum.CURRENT_CATEGORY_BESTSELLERS,
  ListSelectionTypeEnum.CATEGORIES_BESTSELLERS_LAST_CATEGORY_VISITED,
  ListSelectionTypeEnum.CATEGORIES_BESTSELLERS_LAST_PRODUCT_VISITED,
  ListSelectionTypeEnum.CATEGORIES_BESTSELLERS_BEST_CATEGORY_CONTEXT,
  ListSelectionTypeEnum.CATEGORIES_BESTSELLERS_CATEGORY_BEST_PRODUCT_CONTEXT,
  ListSelectionTypeEnum.MANUFACTURERS_BESTSELLERS,
  ListSelectionTypeEnum.CURRENT_MANUFACTURER_BESTSELLERS,
];
const showPeriodField = (selectedValue: ListSelectionTypeEnum) => typesWithPeriodField.includes(selectedValue);

const typesWithSelectedIdsField: ListSelectionTypeEnum[] = [
  ListSelectionTypeEnum.CATEGORIES_BESTSELLERS,
  ListSelectionTypeEnum.CATEGORIES_NEWEST,
  ListSelectionTypeEnum.CATEGORIES_RANDOM,
  ListSelectionTypeEnum.MANUFACTURERS_BESTSELLERS,
  ListSelectionTypeEnum.MANUFACTURERS_NEWEST,
  ListSelectionTypeEnum.MANUFACTURERS_RANDOM,
  ListSelectionTypeEnum.MANUAL_SELECTION,
];
const showSelectedIdsField = (selectedValue: ListSelectionTypeEnum) => typesWithSelectedIdsField.includes(selectedValue);

const getSelectedIdsConfigLabel = (type: ListSelectionTypeEnum): string => {
  switch (type) {
    case ListSelectionTypeEnum.CATEGORIES_BESTSELLERS:
    case ListSelectionTypeEnum.CATEGORIES_NEWEST:
    case ListSelectionTypeEnum.CATEGORIES_RANDOM:
      return 'templateBuilder.fields.categoryList';
    case ListSelectionTypeEnum.MANUFACTURERS_BESTSELLERS:
    case ListSelectionTypeEnum.MANUFACTURERS_NEWEST:
    case ListSelectionTypeEnum.MANUFACTURERS_RANDOM:
      return 'templateBuilder.fields.manufacturerList';
    default:
      return 'templateBuilder.fields.productList';
  }
};

const getSelectedIdsConfigOptions = (type: ListSelectionTypeEnum): {name: string; fields: StringMap; buttonLabel: string; defaultFilters: SpmTableFilter[]} => {
  switch (type) {
    case ListSelectionTypeEnum.CATEGORIES_BESTSELLERS:
    case ListSelectionTypeEnum.CATEGORIES_NEWEST:
    case ListSelectionTypeEnum.CATEGORIES_RANDOM:
      return {
        name: 'ShopsProductsCategoriesList',
        fields: { id: 'id_shop_product_category_list', label: 'name', elementId: 'id_category' },
        buttonLabel: 'templateBuilder.fields.chooseCategories',
        defaultFilters: [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }],
      };
    case ListSelectionTypeEnum.MANUFACTURERS_BESTSELLERS:
    case ListSelectionTypeEnum.MANUFACTURERS_NEWEST:
    case ListSelectionTypeEnum.MANUFACTURERS_RANDOM:
      return {
        name: 'ShopsManufacturers',
        fields: { id: 'id_manufacturer', label: 'name', elementId: 'id_manufacturer' },
        buttonLabel: 'templateBuilder.fields.chooseManufacturers',
        defaultFilters: [],
      };
    default:
      return {
        name: 'ShopsProducts',
        fields: { id: 'id_shop_product', label: 'name', elementId: 'id_product' },
        buttonLabel: 'templateBuilder.fields.chooseProducts',
        defaultFilters: [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
      };
  }
};

const getSelectedIdsConfigs = (selectedValue: ListSelectionTypeEnum) => ({
  componentName: ComponentNameEnum.ITEM_LIST,
  label: getSelectedIdsConfigLabel(selectedValue),
  options: {
    defaultValue: [],
    ...getSelectedIdsConfigOptions(selectedValue),
    showSelectedIdsField,
  },
});

const selectionTypeConfig: BaseFieldConfig = {
  componentName: ComponentNameEnum.SELECTION_TYPE,
  options: {
    list: Object.values(ListSelectionTypeEnum).map((type) => ({ label: `templateBuilder.fields.selectionTypes.${type}`, value: type })),
    defaultValue: ListSelectionTypeEnum.CROSSSELLING,
    periodConfigs: {
      componentName: ComponentNameEnum.STEPPER,
      label: 'templateBuilder.fields.calculPeriod',
      options: {
        sign: StepperSignEnum.NONE,
        showPeriodField,
      },
    },
    selectedIdsConfigs: getSelectedIdsConfigs,
  },
};

export default selectionTypeConfig;
