
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import axios from 'axios';
import { MakeSPMAIRequest } from '@/composables/services/ShopimindAI';
import sha1 from 'sha1';
import { UserState } from '@/composables/User';
import moment from 'moment';
import { useStore } from '@/store';
import { addUrlParameter, sendMessageToSlack } from '@/helpers/helpers';

export default defineComponent({
  name: 'SegmentationPromptAI',

  components: {
    Textarea,
    Button,
  },

  directives: {
    tooltip: Tooltip,
  },

  emits: ['generate-config', 'generate-config-in-progress'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const idShop = ref(UserState?.activeShop?.id ?? 0);
    const hasCorrectOffer = ref(false);
    const store = useStore();

    const promptText = ref('');
    const isLoadingGeneration = ref(false);
    const feedback = ref<string | null>(null);
    const showFeedbackIcons = ref(false);
    const lastAIResponse: any = ref({
      id: null,
      question: null,
      response: null,
    });
    const lastFeedbackID = ref(null);

    const setFeedback = (type: 'like' | 'dislike') => {
      if (lastAIResponse.value.id === lastFeedbackID.value) {
        return;
      }
      feedback.value = type;
      lastFeedbackID.value = lastAIResponse.value.id;

      if (type === 'like') {
        sendMessageToSlack('B08GK97AQG2/AE14VoG8pFEukgLUhp45xQj4', `Id Shop : ${idShop.value}\nPrompt: ${lastAIResponse.value.question}`, lastAIResponse.value.response);
      } else {
        sendMessageToSlack('B08GMRMKMFW/KA4xXavygvmGsIokhzqHtcME', `Id Shop : ${idShop.value}\nPrompt: ${lastAIResponse.value.question}`, lastAIResponse.value.response);
      }
      // TODO : call API to give feedback
    };

    const generateConfig = async () => {
      emit('generate-config-in-progress');
      isLoadingGeneration.value = true;
      feedback.value = null;
      showFeedbackIcons.value = false;

      // sleep 2 seconds for test loading
      // TODO : call API to generate config
      const aiRequestPromise = await MakeSPMAIRequest(
        `config-generator/get-segmentation-from-prompt/${idShop.value}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop.value}`)}`,
        {
          prompt: promptText.value,
        },
      );
      const configGen = aiRequestPromise?.data?.result?.content ?? null;

      let success = true;
      let generatedConfig: any = null;
      if (!configGen || configGen === 'FALSE') {
        success = false;
      } else {
        generatedConfig = JSON.parse(configGen);
      }

      if (success) {
        try {
          const result = await axios.post(
            `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/validate`,
            {
              idShop: idShop.value,
              config: generatedConfig,
            },
          );
          if (!result || (result.status !== 200 && result.status !== 201)) {
            success = false;
          }
        } catch (error) {
          success = false;
        }
      }

      isLoadingGeneration.value = false;
      emit('generate-config', {
        success,
        config: generatedConfig,
      });
      if (success) {
        sendMessageToSlack('B08GG4UCJNR/9RrQf4UmVikP0wAYhUN1Yn1y', `Id Shop : ${idShop.value}\nPrompt: ${promptText.value}`, generatedConfig);
        lastAIResponse.value = {
          id: Math.random(),
          question: promptText.value,
          response: generatedConfig,
        };
        showFeedbackIcons.value = true;
        // eslint-disable-next-line no-empty
      } else {
        sendMessageToSlack('B08H7DEGTCY/SPDhnZIoUBSoOUxnVGtfZobC', `Id Shop : ${idShop.value}\nPrompt: ${promptText.value}`, generatedConfig);
      }
    };

    const watchUserState = () => {
      if (UserState.activeOffer) {
        if (UserState.activeOffer.trialPlan) {
          if (UserState.activeOffer.trialPlanLimit) {
            hasCorrectOffer.value = moment().isBefore(moment(UserState.activeOffer.trialPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasCorrectOffer.value = true;
          }
        } else if (UserState.activeOffer.forcedPlan) {
          if (UserState.activeOffer.forcedPlanLimit) {
            hasCorrectOffer.value = moment().isBefore(moment(UserState.activeOffer.forcedPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasCorrectOffer.value = true;
          }
        } else if (UserState.activeOffer.currentPlan) {
          hasCorrectOffer.value = true;
        } else {
          hasCorrectOffer.value = false;
        }
      } else if (!UserState.activeOffer && UserState.hasOfferV3) {
        hasCorrectOffer.value = false;
      }
    };

    const updatePlan = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    watch(() => UserState.activeOffer, () => {
      watchUserState();
    }, { deep: true });

    onMounted(() => {
      watchUserState();
      // Ajouter un paramètre à l'URL afin de pouvoir charger gleap via url
      addUrlParameter('cp', 'perfectsegmentai');
    });

    return {
      t,
      updatePlan,
      promptText,
      generateConfig,
      isLoadingGeneration,
      feedback,
      setFeedback,
      showFeedbackIcons,
      hasCorrectOffer,
    };
  },
});
