
import {
  computed,
  defineComponent, ref,
  nextTick,
} from 'vue';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import {
  ToolbarItemsModel,
  ListType,
} from '@/types';
import {
  clearVariables,
} from '@/composables/mes-listes/ImportCustomers';
import EditorModal from '@/components/modals/EditorModal.vue';
import Panel from 'primevue/panel';
import SettingsPanelSegmentations
  from '@/views/mes-listes/customers/ImportCustomers/settings/SettingsPanelSegmentations.vue';
import { store } from '@/store';
import SettingsPanelManual from '@/views/mes-listes/customers/ImportCustomers/settings/SettingsPanelManual.vue';
import ConfigManual from '@/views/mes-listes/customers/ImportCustomers/configs/ConfigManual.vue';
import { UserState } from '@/composables/User';
import { ShopsListsInputItem, ShopsListsUpdateInputItem } from '@/types/generated-types/graphql';
import { InsertShopsLists, UpdateShopsLists } from '@/composables/shop/ShopsLists';
import { showToastError, showToastSuccess } from '@/helpers';
import router from '@/router';
import SegmentationsPanel from '@/components/segmentations/SegmentationsPanel.vue';
import useVuelidate from '@vuelidate/core';
import {
  required,
} from '@vuelidate/validators';

export default defineComponent({
  name: 'ImportCustomers',

  directives: {
    tooltip: Tooltip,
  },

  components: {
    SegmentationsPanel,
    ConfigManual,
    SettingsPanelManual,
    SettingsPanelSegmentations,
    Panel,
    EditorModal,
  },

  setup() {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const settingPanelValidationErrors = ref({});
    const listId = computed(() => store.getters['listImportEditor/getIdShopList']);
    const listName = computed(() => store.getters['listImportEditor/getListName']);
    const listType = computed(() => store.getters['listImportEditor/getListType']);
    const typeImport = computed(() => store.getters['listImportEditor/getTypeImport']);
    const isSettingPanelVisible = computed(() => store.getters['listImportEditor/getIsSettingPanelVisible']);
    const segmentationHasError = computed(() => store.getters['segmentationsEditor/getHasError']);
    const settingsData = computed(() => store.getters['listImportEditor/getSettingsData']);
    const preview = ref(false);

    const createNewList = async () => {
      const newShopLists: ShopsListsInputItem = {
        id_shop: idShop.value,
        name: listName.value,
        total_contacts: 0,
        total_subscribers: 0,
        total_unsubscribeds: 0,
        total_sends: 0,
        total_sends_soft_bounce: 0,
        total_sends_hard_bounce: 0,
        total_sends_spam: 0,
        total_newsletters_viewed: 0,
        total_newsletters_clicked: 0,
        date_add: new Date(),
        date_modification: new Date(),
        active: 1,
        rating: 0,
        valid: true,
        whitelist: false,
        type: typeImport.value === 'segmentation' ? ListType.SEGMENT : ListType.LIST,
      };

      const newList = await InsertShopsLists([newShopLists]);

      return (newList && newList.status && newList.id) ? newList.id : 0;
    };

    const saveImportCustomersAction = async (stay: boolean) => {
      const rules = {
        listName: {
          required,
        },
      };
      const v$ = useVuelidate(rules, {
        listName: listName.value,
      });

      const success = await v$.value.$validate();

      if (!success) {
        store.commit('listImportEditor/setIsSettingPanelVisible', true);
        await nextTick();
        settingPanelValidationErrors.value = v$;
      } else {
        let newList = 0;

        if (typeImport.value === 'segmentation') {
          await store.dispatch('segmentationsEditor/validate');
          if (segmentationHasError.value) {
            await showToastError('noSave');
            return;
          }
        }

        /* If the list is new, we create it */
        if (listId.value <= 0) {
          // créer une liste
          newList = await createNewList();

          if (newList > 0) {
            store.commit('listImportEditor/setIdShopList', newList);
          } else {
            await showToastError('noSave');
            return;
          }
        }

        const urlId = listId.value > 0 ? listId.value : newList;

        // Update list type if type is not defined yet
        if (listType.value === ListType.NOT_DEFINED) {
          const updatedShopsList: ShopsListsUpdateInputItem = {
            id_shop_list: urlId,
            type: typeImport.value === 'segmentation' ? ListType.SEGMENT : ListType.LIST,
          };

          const result = await UpdateShopsLists([updatedShopsList]);
          if (result.err) {
            await showToastError(t('errorMessages.GENERIC_ERROR'));
            return;
          }
        }

        /* Save Import */
        if (typeImport.value === 'segmentation') {
          const result = await store.dispatch('segmentationsEditor/save', {
            idShop: idShop.value,
            idShopList: listId.value,
            stayUpdated: settingsData.value.keepListUpdated,
            listName: listName.value,
          });
          if (result.err) {
            await showToastError(t('errorMessages.GENERIC_ERROR'));
            return;
          }
          store.commit('listImportEditor/setIsEditorVisible', false);
          store.commit('segmentationsEditor/resetState');
          store.commit('listImportEditor/setIsSettingPanelVisible', true);
          await showToastSuccess(t('myLists.importContacts.segmentation.saved'));
        } else {
          await store.dispatch('listImportEditor/saveImportCustomersManualAction', { idShop: idShop.value });
        }

        await router.push({
          name: 'lists.manage-customers',
          params: { id: urlId },
        });
      }
    };

    const toolbarRightButtons: ToolbarItemsModel[] = [
      {
        icon: 'fa-regular fa-magnifying-glass',
        label: t('preview'),
        command: () => {
          preview.value = !preview.value;
        },

        hide: typeImport.value === 'segmentation',
      },
      {
        label: t('templateBuilder.save'),
        items: [
          {
            label: t('templateBuilder.saveAndLeave'),
            command: async () => {
              store.commit('general/showTheSpinner');
              saveImportCustomersAction(false).then(() => {
                store.commit('general/hideTheSpinner');
                store.commit('listImportEditor/setIsSettingPanelVisible', false);
              });
            },
          },
          {
            label: t('templateBuilder.leaveWithoutSaving'),
            command: () => {
              store.commit('listImportEditor/resetState');
              store.commit('segmentationsEditor/resetState');
              store.commit('listImportEditor/setIsEditorVisible', false);
              store.commit('listImportEditor/setIsSettingPanelVisible', false);
              clearVariables();
            },
          },
        ],
      },
    ];

    const contentHeaderStartToolbar = computed(() => ([
      {
        icon: 'far fa-fw fa-cog',
        label: t('automatedScenarios.toolbar.label.settings'),
        tooltip: t('myLists.importContacts.panelTitle'),
        class: () => (isSettingPanelVisible.value ? 'active' : ''),
        command: () => store.commit('listImportEditor/setIsSettingPanelVisible', !isSettingPanelVisible.value),
      },
    ]));

    const closeSettings = () => {
      store.commit('listImportEditor/setIsSettingPanelVisible', false);
    };

    // onMounted(() => {
    //   if (toolbarRightButtons[1] && toolbarRightButtons[1].items) {
    //     if (typeImport.value === 'segmentation') {
    //       toolbarRightButtons[1].items.push({
    //         label: t('templateBuilder.saveAndStay'),
    //         command: async () => {
    //           await saveImportCustomersAction(true);
    //         },
    //       });
    //     }
    //     toolbarRightButtons[1].items.push({
    //       label: t('templateBuilder.leaveWithoutSaving'),
    //       command: () => {
    //         store.commit('listImportEditor/resetState');
    //         store.commit('listImportEditor/setIsEditorVisible', false);
    //         clearVariables();
    //       },
    //     });
    //   }
    // });

    return {
      t,
      listName,
      typeImport,
      preview,
      toolbarRightButtons,
      contentHeaderStartToolbar,
      isSettingPanelVisible,
      settingPanelValidationErrors,
      closeSettings,
    };
  },
});
