<template>
  <div
    v-if="getInitialized"
    :class="size === 'small' ? 'overflow-x-hidden': ''"
  >
    <div
      v-if="origin === 'list'"
      class="flex justify-content-between flex-wrap mb-4"
    >
      <div class="flex align-items-center justify-content-center">
        <h3>
          {{ t('segmentation.panelTitle') }}
        </h3>
      </div>
      <div class="flex align-items-center justify-content-center gap-1">
        <Tag
          class="contact-number-container"
          severity="info"
        >
          <div class="flex gap-1 justify-content-center align-items-center">
            <span>{{ t('segmentation.totalContactsLabel') }}</span>
            <span v-if="!loadContactsNumber">
              {{ totalContactsNumber !== null ? formatNumbersInString(totalContactsNumber.toString()) : 'N/A' }}
            </span>
            <Skeleton
              v-else
              height="0.75rem"
              width="4rem"
            />
          </div>
        </Tag>
        <Button
          icon="far fa-arrows-rotate"
          class="p-button-text p-button-secondary p-button-xs"
          :loading="loadContactsNumber"
          @click="handleContactsNumber"
        />
      </div>
    </div>
    <div
      v-if="showPromptAI"
      class="flex justify-content-left align-items-left mb-3"
    >
      <SegmentationPromptAI
        @generate-config-in-progress="AIGenerationInProgress = true"
        @generate-config="handleGenerationByAI"
      />
    </div>
    <div class="flex flex-column flex-grow-1 rule-list relative">
      <Loader
        v-if="AIGenerationInProgress"
      />
      <div
        v-for="(blockAND, index) in segmentsConfiguration"
        :key="`blockAND-${index}-${cleanedDataKey}`"
      >
        <div
          v-if="Number(index) !== 0"
          class="connector"
        >
          <div class="connector-choose-button">
            <div
              class="connector-option and"
            >
              {{ t('andUpper') }}
            </div>
            <div
              class="connector-option or"
              @click="moveBlockAndToBlockOr(index)"
            >
              {{ t('orUpper') }}
            </div>
          </div>
        </div>
        <div
          class="rule-container relative"
        >
          <SegmentationBlockAND
            :key="`blockAND-${index}-${refreshComponents}`"
            :index-block-and="index"
            :block-and="blockAND"
            :size="size"
          />
        </div>
      </div>
    </div>
  </div>
  <Loader v-else />
</template>

<script lang="ts">
import {
  computed,
  defineComponent, watch, ref, PropType, SetupContext, onBeforeUnmount, onMounted,
} from 'vue';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import SegmentationBlockAND from '@/components/segmentations/SegmentationBlockAND.vue';
import { useI18n } from 'vue-i18n';
import { store } from '@/store';
import Loader from '@/components/layout/Loader.vue';
import validateSegmentProperty from '@/components/segmentations/utils/utils';
import axios from 'axios';
import { UserState } from '@/composables/User';
import { showToastError, showToastSuccess } from '@/helpers';
import { formatNumbersInString } from '@/helpers/Number';
import SegmentationPromptAI from '@/components/segmentations/SegmentationPromptAI.vue';

export default defineComponent({
  name: 'SegmentationsPanel',
  components: {
    SegmentationPromptAI,
    Loader,
    SegmentationBlockAND,
    Button,
    Tag,
    Skeleton,
  },

  props: {
    modelValue: {
      type: Object as PropType<any>,
      required: false,
      default: () => ({}),
    },

    origin: {
      type: String,
      required: false,
      default: '',
    },

    size: {
      type: String,
      required: false,
      default: '',
    },

    showPromptAI: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup(props: {
    modelValue: any;
    origin: string;
    size: string;
    showPromptAI: boolean;
  }, { emit }: SetupContext) {
    const { t } = useI18n();
    const idShop = UserState?.activeShop?.id ?? 0;
    const cleanedDataKey = ref(0);
    const AIGenerationInProgress = ref(false);
    const segmentsConfiguration: any = computed(() => store.getters['segmentationsEditor/getConfiguration']);
    const listImportId: any = computed(() => store.getters['segmentationsEditor/getShopListImportId']);

    const refreshComponents: any = computed(() => store.getters['segmentationsEditor/getRefreshComponents']);

    const getInitialized: any = computed(() => store.getters['segmentationsEditor/getInitialized']);

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);

    const refreshContactsNumber = computed(() => store.getters['segmentationsEditor/getRefreshContactsNumber']);

    const loadContactsNumber = ref(false);

    const totalContactsNumber: any = ref(0);

    const addBlockAND = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', {
            type: 'segments',
            value: { segmentId, value: validation.validate.value },
          });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      store.commit('segmentationsEditor/addBlockAND');
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
      return true;
    };

    const cleanData = (data: any) => {
      const cleanedData = data.map((subArray: any) => subArray.filter((obj: any) => Object.keys(obj).length !== 0));
      return cleanedData.filter((subArray: any) => subArray.length > 0);
    };

    const handleContactsNumber = async () => {
      loadContactsNumber.value = true;
      const tmpCleanedData = cleanData(segmentsConfiguration.value);
      try {
        const result = await axios.post(
          `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/count`,
          {
            idShop,
            config: tmpCleanedData,
          },
        );

        if (result && (result.status === 200 || result.status === 201)) {
          if (result.data.error) {
            totalContactsNumber.value = null;
          } else {
            totalContactsNumber.value = result.data;
          }
        } else {
          totalContactsNumber.value = 0;
          await showToastError('GENERIC_ERROR');
        }
      } catch (e) {
        totalContactsNumber.value = 0;
        await showToastError('GENERIC_ERROR');
      }

      loadContactsNumber.value = false;
      store.commit('segmentationsEditor/setRefreshContactsNumber', false);
    };

    const handleGenerationByAI = async (generatedConfig: any) => {
      if (generatedConfig.success === false) {
        AIGenerationInProgress.value = false;
        await showToastError(t('segmentations.promptAI.error'));
        return;
      }

      store.commit('segmentationsEditor/setConfiguration', generatedConfig.config);
      await handleContactsNumber();
      AIGenerationInProgress.value = false;
      await showToastSuccess(t('segmentations.promptAI.success'));
    };

    const moveBlockAndToBlockOr = (index: any) => {
      store.commit('segmentationsEditor/moveBlockAndToBlockOr', index);
      handleContactsNumber();
    };

    watch([segmentsConfiguration], () => {
      if (!segmentsConfiguration.value.length || !segmentsConfiguration.value) {
        store.commit('segmentationsEditor/initConfiguration');
      }
      emit('update:modelValue', segmentsConfiguration.value);
    }, { deep: true });

    watch([refreshContactsNumber], async () => {
      // TODO : only if comming from import page
      if (refreshContactsNumber.value) {
        await handleContactsNumber();
      }
    });

    onMounted(async () => {
      const dataToUse = props.origin === 'list' ? segmentsConfiguration.value : props.modelValue;
      await store.dispatch('segmentationsEditor/initialization',
        { originCall: props.origin, configSegmentation: dataToUse, listImportId: listImportId.value ?? 0 });
      // await handleContactsNumber();
    });

    onBeforeUnmount(() => {
      store.commit('segmentationsEditor/resetState');
    });

    return {
      t,
      cleanedDataKey,
      getInitialized,
      refreshComponents,
      segmentsConfiguration,
      loadContactsNumber,
      totalContactsNumber,
      handleContactsNumber,
      handleGenerationByAI,
      AIGenerationInProgress,
      moveBlockAndToBlockOr,
    };
  },

  methods: { formatNumbersInString },
});
</script>

<style lang="scss" scoped>
.contact-number-container {
  background-color: #f8f9fa !important;
  color: #495057 !important;
  border: 1px solid #dee2e6;
}

.connector {
  position: relative;
  width: 100%;
  height: 4rem;
}

.line {
  width: 1px;
  height: 100%;
  background-color: #e9ecef;
  position: relative;
  left: 2rem; /* Position à 10px du bord gauche */
}

.connector-choose-button {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  background-color: white;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  width: 10rem;
  height: 40px;
  border: 1px solid #ccc;
  overflow: hidden;
  cursor: pointer;
}

.connector-option {
  flex: 1;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  background: white;
  transition: background 0.3s, color 0.3s;

  &.and {
    color: white;
    cursor: pointer;
    background: #495057;
  }

  &.or {
    cursor: pointer;
    color: black;

    &:hover {
      background: #f8f9fa;
    }
  }
}
</style>

<style lang="scss">
.and-tag .p-tag {
  background-color: #e9ecef;
  color: black;
}
</style>
