<template>
  <div class="unit-box p-2">
    <div
      :class="`blockOR-list flex flex-column pt-${paddingSize} pr-${paddingSize} pb-${paddingSize} relative`"
    >
      <div
        v-for="(blockOR, index) in dataBlockAND"
        :key="`blockOR-${index}`"
      >
        <div
          v-if="Number(index) !== 0"
          class="connector"
        >
          <div class="connector-choose-button">
            <div
              class="connector-option and"
              @click="moveBlockOrToBlockAnd(index)"
            >
              {{ t('andUpper') }}
            </div>
            <div class="connector-option or">
              {{ t('orUpper') }}
            </div>
          </div>
        </div>
        <div class="blockOR-container relative">
          <SegmentationBlockOR
            :key="`blockOR-${index}`"
            :index-block-and="indexBlockAnd"
            :index-block-or="index"
            :block-or="blockOR"
            :length-block-and="dataBlockAND.length"
            :size="size"
          />
        </div>
      </div>
      <div class="add-block-container flex align-items-center mt-2">
        <Button
          :label="t('segmentation.addCondition')"
          class="p-button-secondary addCondition"
          icon="fa-solid fa-circle-plus fa-lg"
          @click="addBlockOR"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, reactive, computed,
} from 'vue';
import Button from 'primevue/button';
import SegmentationBlockOR from '@/components/segmentations/SegmentationBlockOR.vue';
import { store } from '@/store';
import { useI18n } from 'vue-i18n';
import validateSegmentProperty from '@/components/segmentations/utils/utils';

export default defineComponent({
  name: 'SegmentationBlockAND',
  components: {
    SegmentationBlockOR,
    Button,
  },

  props: {
    blockAnd: {
      type: Array,
      required: false,
      default: () => [],
    },

    indexBlockAnd: {
      type: Number,
      required: true,
    },

    size: {
      type: String,
      required: false,
      default: '',
    },
  },

  setup(props) {
    const { t } = useI18n();
    const dataBlockAND = reactive(props.blockAnd);

    const currentSegmentConfig = computed(() => store.getters['segmentationsEditor/getCurrentSegmentConfig']);
    const paddingSize = computed(() => (props.size === 'small' ? '1' : '2'));

    const addBlockOR = async () => {
      if (currentSegmentConfig.value) {
        const { segmentId } = currentSegmentConfig.value.configProperty;
        const validation = await validateSegmentProperty(currentSegmentConfig.value.configProperty, currentSegmentConfig.value.propertyDefinition);
        if (validation && !validation.success) {
          store.commit('segmentationsEditor/pushError', { type: 'segments', value: { segmentId, value: validation.validate.value } });
          return false;
        }
        store.commit('segmentationsEditor/removeError', { type: 'segments', id: 'segmentId', value: segmentId });
        store.commit('segmentationsEditor/setRefreshContactsNumber', true);
      }
      const { indexBlockAnd } = props;
      store.commit('segmentationsEditor/addBlockOR', indexBlockAnd);
      store.commit('segmentationsEditor/setCurrentSegmentConfig', null);
    };

    const moveBlockOrToBlockAnd = (fromIndexBlockOR: any) => {
      const { indexBlockAnd } = props;
      store.commit('segmentationsEditor/moveBlocksORtoBlockAND', {
        indexBlockAND: indexBlockAnd,
        fromIndexBlockOR,
      });
      store.commit('segmentationsEditor/setRefreshContactsNumber', true);
    };

    return {
      t,
      dataBlockAND,
      paddingSize,
      addBlockOR,
      moveBlockOrToBlockAnd,
    };
  },
});
</script>

<style lang="scss" scoped>
.unit-box {
  border-radius: 8px;
  background-color: white;
  border: 1px solid #e9ecef;
}

.blockOR-container {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}

.connector {
  position: relative;
  width: 100%;
  height: 4rem;
}

.addCondition {
  font-size: 13px !important;
  font-weight: bold;
}

.connector-choose-button {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  background-color: white;
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  height: 40px;
  width: 10rem;
  border: 1px solid #ccc;
  overflow: hidden;
  cursor: pointer;
}

.connector-option {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-size: 16px;
  font-weight: bold;
  background: white;
  transition: background 0.3s, color 0.3s;

  &.and {
    color: black;
    cursor: pointer;

    &:hover {
      background: #f8f9fa;
    }
  }

  &.or {
    color: white;
    cursor: pointer;
    background: #495057;
  }
}
</style>

<style lang="scss">
.or-tag .p-tag {
  background-color: #e9ecef;
  color: black;
}
</style>
