
import {
  defineComponent, onBeforeMount, PropType, ref, SetupContext, toRaw, watch,
} from 'vue';
import { useI18n } from 'vue-i18n';
import DeclencheurAudienceSetupMetadata, {
  DeclencheurAudienceSetupData,
} from '@/components/automated-scenarios/metadata/declencheurs/DeclencheurAudienceSetupMetadata';
import { List } from '@/composables/GraphQL';
import { OperatorType, ShopsLists } from '@/types/generated-types/graphql';
import { UserState } from '@/composables/User';
import SegmentationsPanel from '@/components/segmentations/SegmentationsPanel.vue';
import MultiSelect from 'primevue/multiselect';
import PanelAccordion, { SpmPanelAccordionInterface } from '@/components/automated-scenarios/PanelAccordion.vue';
import Message from 'primevue/message';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import ButtonGroup from '@/components/automated-scenarios/fields/ButtonGroup.vue';
import ListCreatePanel from '@/components/mes-lists/ListCreatePanel.vue';
import { myListsState } from '@/composables/mes-listes/ListsManage';
import Chip from 'primevue/chip';
import { asInt } from '@/helpers';

export default defineComponent({
  name: 'DeclencheurAudienceSetup',
  components: {
    ListCreatePanel,
    ButtonGroup,
    FieldErrors,
    SegmentationsPanel,
    MultiSelect,
    PanelAccordion,
    Message,
    Chip,
  },

  props: {
    modelValue: {
      type: Object as PropType<DeclencheurAudienceSetupData>,
      required: true,
      default() {
        return DeclencheurAudienceSetupMetadata.Create();
      },
    },

    formValidation: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  emits: ['update:modelValue'],

  setup(props: { modelValue: DeclencheurAudienceSetupData; formValidation: any }, context: SetupContext) {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
    const segmentTheListData = ref(props.modelValue.segment_the_list);
    const mountedComponent = ref(false);
    const panelsKeys = ref(0);
    const panels: SpmPanelAccordionInterface[] = [
      {
        id: 'choice_list',
        title: 'Choix des listes de diffusion',
      },
      {
        id: 'setup_segmentation',
        title: 'Segmentation de l\'audience',
      },
    ];

    const formValidationErrors = ref(props.formValidation);
    const componentFieldErrorsKey = ref(0);
    watch(() => props.formValidation, () => {
      formValidationErrors.value = props.formValidation;
      componentFieldErrorsKey.value += 1;
    });

    const segmentTheListOptions = [
      { value: '0', label: t('no') },
      { value: '1', label: t('yes') },
    ];

    const listsOptions: any = ref([
      {
        label: 'Lists',
        code: 'lists',
        items: [],
      },
      {
        label: 'Segments',
        code: 'segments',
        items: [],
      },
    ]);

    const lists: any = ref([]);
    const segmentsConfiguration: any = ref(null);

    const update = () => {
      if (mountedComponent.value) {
        context.emit('update:modelValue', DeclencheurAudienceSetupMetadata.Create({
          /* Format the data by creating an array containing the ids of the lists to store them in the box data */
          lists: lists.value.map((item: any) => item.value),
          segment_the_list: segmentTheListData.value,
          segmentation_config: segmentsConfiguration.value,
        }));
      }
    };

    const initListsOptions = async () => {
      const fetchListsByType = async (type: string) => {
        const { items, err } = await List<ShopsLists>({
          name: 'ShopsLists',
          settings: {
            filter: [
              {
                field: 'id_shop',
                operator: OperatorType.Equals,
                value: idShop.value,
              },
              {
                field: 'active',
                operator: OperatorType.Equals,
                value: 1,
              },
              {
                field: 'type',
                operator: OperatorType.Equals,
                value: type,
              },
              {
                field: 'valid',
                operator: OperatorType.Equals,
                value: 1,
              },
            ],
            order: [
              { field: 'id_shop_list', type: 'ASC' },
            ],
            limit: 0,
            offset: 0,
          },
          fields: ['id_shop_list', 'name'],
        });

        if (err === '') {
          const result: { label: string; value: string | number }[] = items.map((item) => ({
            label: item.name,
            value: item.id_shop_list,
          }));

          return result;
        }

        return [];
      };

      listsOptions.value[0].items = await fetchListsByType('list');
      listsOptions.value[1].items = await fetchListsByType('segment');
    };

    watch([lists, segmentTheListData, segmentsConfiguration], update, { deep: true });

    watch(() => myListsState.refreshComponent, async () => {
      if (myListsState.refreshComponent !== 0) {
        const newListId = myListsState.refreshComponent;
        await initListsOptions();
        myListsState.refreshComponent = 0;

        let matchingCurrentLists = listsOptions.value[0].items.filter((item: any) => item.value === asInt(newListId));
        matchingCurrentLists = matchingCurrentLists.length ? matchingCurrentLists
          : listsOptions.value[1].items.filter((item: any) => item.value === asInt(newListId));

        if (matchingCurrentLists.length) {
          const { label, value } = matchingCurrentLists[0];
          lists.value.push({
            label,
            value,
          });
        }
        update();
      }
    });

    onBeforeMount(async () => {
      await initListsOptions();

      if (props.modelValue.segmentation_config) {
        segmentsConfiguration.value = toRaw(props.modelValue.segmentation_config);
      }

      /* Format data by creating an array containing objects { name: string; code: string }
      * to be used by the MultiSelect component */
      props.modelValue.lists.forEach((list) => {
        let matchingCurrentLists = listsOptions.value[0].items.filter((item: any) => item.value === list);
        matchingCurrentLists = matchingCurrentLists.length ? matchingCurrentLists
          : listsOptions.value[1].items.filter((item: any) => item.value === list);
        if (matchingCurrentLists.length) {
          const { label, value } = matchingCurrentLists[0];
          lists.value.push({
            label,
            value,
          });
        }
      });

      mountedComponent.value = true;
      update();
    });

    return {
      t,
      panelsKeys,
      panels,
      lists,
      listsOptions,
      segmentsConfiguration,
      componentFieldErrorsKey,
      formValidationErrors,
      segmentTheListData,
      segmentTheListOptions,
      mountedComponent,
    };
  },
});
