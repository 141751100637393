import {
  WidgetTypeConfig,
  ConfigurationPanelStructureItemEnum,
  TextAlignValueEnum,
  ComponentNameEnum,
  SmartProductListLayoutEnum,
  StepperSignEnum,
  TemplateStructureEnum,
} from '@/types';
import getHorizontalSpacesFieldConfig from '@/components/template-builder/config/fields/horizontal-spaces-config';
import getVerticalSpacesFieldConfig from '@/components/template-builder/config/fields/vertical-spaces-config';
// eslint-disable-next-line import/no-cycle
import {
  setCssProperty,
  setMediaDisplay,
  setListLayout,
  setTruncateLimit,
  setSmartListProperty,
  setDisplayedElements,
  setSmartListHtmlCode,
  setSmartListTitle,
  setSmartListButtonTitle,
  recalculateLineHeightValue,
  setCssPropertyAndRemoveIfZero, setSmartListButtonAlignment, setSmartListImageWidth, setCssButtonWidthProperty,
} from '@/components/template-builder/setters';
// eslint-disable-next-line import/no-cycle
import {
  getAttributeByName,
  getClassByName,
  getCssPropertyByName,
  getTruncateLimit,
  getDisplayedElements,
  getSmartListHtmlCode,
  getSmartListProperty,
  getListLayout,
  getSmartListTitle, getSmartListContent,
} from '@/components/template-builder/getters';
import variables from '@/components/template-builder/utils/variables-list';
// eslint-disable-next-line import/no-cycle
import { refreshSmartProductList } from '@/components/template-builder/callbacks';
import getColorFieldConfig from '@/components/template-builder/config/fields/color-config';
import getTextAlignFieldConfig from '@/components/template-builder/config/fields/text-align-config';
import bordersFieldConfig from '@/components/template-builder/config/fields/borders-config';
import getWidthFieldConfig from '@/components/template-builder/config/fields/width-config';
import getElementAlignFieldConfig from '@/components/template-builder/config/fields/element-align-config';
import getBorderRadiusFieldConfig from '@/components/template-builder/config/fields/border-radius-config';
import mediaDisplayFieldConfig from '@/components/template-builder/config/fields/media-display-config';
import getInputVariablesFieldConfig from '@/components/template-builder/config/fields/input-variables-config';
import selectionTypeConfig from '@/components/template-builder/config/fields/selection-type-config';
import getItemListFieldConfig from '@/components/template-builder/config/fields/item-list-config';
import listItemsFieldConfig from '@/components/template-builder/config/fields/list-items-config';
import getCodeFieldConfig from '@/components/template-builder/config/fields/code-config';
import { OperatorType } from '@/types/generated-types/graphql';
import getSpmIconsFieldConfig from '@/components/template-builder/config/fields/spm-icons-config';
import getStepperFieldConfig from '@/components/template-builder/config/fields/stepper-config';
import { UserState } from '@/composables/User';
import lineHeightFieldConfig from '@/components/template-builder/config/fields/line-height-config';
import fontFieldConfig from '@/components/template-builder/config/fields/font-config';
import getButtonWidthFieldConfig from '@/components/template-builder/config/fields/button-width-config';

const widgetSmartProductListConfig: WidgetTypeConfig = {
  tabPanel: {
    label: 'templateBuilder.widgetsHeaders.smartProductList',
    items: [
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.widgetConfiguration',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListConfiguration.list',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.listTitle',
                  selector: '{SELECTOR} .spm_product_list_title',
                  properties: [{
                    // eslint-disable-next-line max-len
                    name: 'listTitle', getter: getSmartListTitle, setters: [setSmartListTitle], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getInputVariablesFieldConfig('', variables.allVariables, false, false),
                },
                {
                  label: 'templateBuilder.fields.productListLayout',
                  selector: '{SELECTOR}',
                  properties: [
                    {
                      name: 'spm_product_list_',
                      getter: getListLayout,
                      setters: [setListLayout],
                      callbacks: [
                        refreshSmartProductList,
                      ],
                      refreshWidgetFields: true,
                    },
                  ],
                  ...getSpmIconsFieldConfig(
                    Object.values(SmartProductListLayoutEnum),
                    'https://media.shopimind.io/img/builder-icons/icon-disposition/{key}.png',
                    'spm_product_list_',
                    SmartProductListLayoutEnum.HORIZONTAL_1,
                  ),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListConfiguration.products',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.selectionType',
                  selector: '{SELECTOR} .spm_product_list_element_container .spm_product_list_element_row',
                  properties: [
                    {
                      name: 'engine', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                    },
                    {
                      name: 'nb_weeks', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                    },
                    {
                      name: 'ids', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                    },
                  ],
                  ...selectionTypeConfig,
                },
                {
                  label: 'templateBuilder.fields.productList',
                  selector: '{SELECTOR} .spm_product_list_element_row',
                  properties: [{
                    name: 'excluded_ids', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getItemListFieldConfig(
                    [],
                    'ShopsProducts',
                    { id: 'id_shop_product', label: 'name', elementId: 'id_product' },
                    'templateBuilder.fields.excludeProducts',
                    [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }, { field: 'active', value: true, operator: OperatorType.Equals }],
                  ),
                },
                {
                  label: 'templateBuilder.fields.categoryList',
                  selector: '{SELECTOR} .spm_product_list_element_row',
                  properties: [{
                    name: 'excluded_categories', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getItemListFieldConfig(
                    [],
                    'ShopsProductsCategoriesList',
                    { id: 'id_shop_product_category_list', label: 'name', elementId: 'id_category' },
                    'templateBuilder.fields.excludeCategories',
                    [{ field: 'lang', value: UserState.user.lang ?? 'fr', operator: OperatorType.Equals }],
                  ),
                },
                {
                  label: 'templateBuilder.fields.manufacturerList',
                  selector: '{SELECTOR} .spm_product_list_element_row',
                  properties: [{
                    name: 'excluded_manufacturers',
                    getter: getSmartListProperty,
                    setters: [setSmartListProperty],
                    callbacks: [refreshSmartProductList],
                    refreshWidgetFields: true,
                  }],
                  ...getItemListFieldConfig([], 'ShopsManufacturers', { id: 'id_manufacturer', label: 'name', elementId: 'id_manufacturer' }, 'templateBuilder.fields.excludeManufacturers', []),
                },
                {
                  label: 'templateBuilder.fields.maxDisplayedProducts',
                  selector: '{SELECTOR} .spm_product_list_element_container',
                  properties: [{
                    name: 'nb', getter: getSmartListProperty, setters: [setSmartListProperty], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getStepperFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.displayedElements',
                  selector: '{SELECTOR}',
                  properties: [
                    {
                      name: 'displayedElements',
                      getter: getDisplayedElements,
                      setters: [setDisplayedElements],
                      callbacks: [refreshSmartProductList],
                      refreshWidgetFields: true,
                    },
                  ],
                  ...listItemsFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonText',
                  selector: '{SELECTOR} .spm_product_list_element_button > tbody > tr > th > a',
                  properties: [{
                    name: 'title', getter: getSmartListContent, setters: [setSmartListButtonTitle], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getInputVariablesFieldConfig('Texte du bouton', variables.allVariables, false, false),
                },
                {
                  label: 'templateBuilder.fields.maxProductTitleCharacters',
                  selector: 'product.name',
                  properties: [{
                    name: 'truncate', getter: getTruncateLimit, setters: [setTruncateLimit], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getStepperFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.maxProductDescriptionCharacters',
                  selector: 'product.description_short',
                  properties: [{
                    name: 'truncate', getter: getTruncateLimit, setters: [setTruncateLimit], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getStepperFieldConfig(),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.fields.htmlCode',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: '',
                  selector: '{SELECTOR}',
                  properties: [
                    {
                      name: 'content',
                      getter: getSmartListHtmlCode,
                      setters: [setSmartListHtmlCode],
                      callbacks: [
                        refreshSmartProductList,
                      ],
                      refreshWidgetFields: true,
                    },
                  ],
                  ...getCodeFieldConfig('', true, variables.allVariables),
                },
              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.productListStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.productListBackgroundColor',
            selector: '{SELECTOR} .spm_product_list > tbody > tr > td',
            properties: [{
              name: 'background-color',
              getter: getCssPropertyByName,
              setters: [setCssProperty],
            }],
            ...getColorFieldConfig(),
          },
          {
            label: 'templateBuilder.fields.productListBorders',
            selector: '{SELECTOR} .spm_product_list > tbody > tr > td',
            properties: [
              {
                name: 'border-color',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-style',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-width',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'border-bottom-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-bottom-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-bottom-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-top-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-top-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-left-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-left-width',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-style',
                getter: getCssPropertyByName,
                setters: [setCssProperty],
              },
              {
                name: 'border-right-color',
                getter: getCssPropertyByName,
                setters: [],
              },
              {
                name: 'border-right-width',
                getter: getCssPropertyByName,
                setters: [],
              },
            ],
            ...bordersFieldConfig,
          },
          {
            label: 'templateBuilder.fields.productMarginVertical',
            selector: '{SELECTOR} .spm_product_list_element',
            properties: [
              {
                name: 'padding-top',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-bottom',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
            ],
            ...getVerticalSpacesFieldConfig('padding'),
          },
          {
            label: 'templateBuilder.fields.productMarginHorizontal',
            selector: '{SELECTOR} .spm_product_list_element',
            properties: [
              {
                name: 'padding-left',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
              {
                name: 'padding-right',
                getter: getCssPropertyByName,
                setters: [setCssPropertyAndRemoveIfZero],
              },
            ],
            ...getHorizontalSpacesFieldConfig('padding'),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.productListTitleStyles',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.font',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [
              { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
              { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...fontFieldConfig,
          },
          {
            label: 'templateBuilder.fields.titleAlign',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
          },
          {
            label: 'templateBuilder.fields.lineHeight.label',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [{ name: 'line-height', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...lineHeightFieldConfig,
          },
          {
            label: 'templateBuilder.fields.spaceBetweenChars',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getStepperFieldConfig(StepperSignEnum.PX),
          },
          {
            label: 'templateBuilder.fields.titleMarginVertical',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [
              { name: 'margin-top', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-bottom', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getVerticalSpacesFieldConfig('margin'),
          },
          {
            label: 'templateBuilder.fields.titleMarginHorizontal',
            selector: '{SELECTOR} .spm_product_list_title',
            properties: [
              { name: 'margin-left', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'margin-right', getter: getCssPropertyByName, setters: [setCssProperty] },
            ],
            ...getHorizontalSpacesFieldConfig('margin'),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.TAB_ITEM,
        tabItemType: TemplateStructureEnum.GROUP,
        label: 'templateBuilder.configs.productListProductStyles.header',
        tabPanel: {
          items: [
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.title',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} .spm_product_list_element_title, {SELECTOR} a.spm_product_list_element_title, {SELECTOR} .spm_product_list_element_title a, '
                    + '{SELECTOR} a.spm_product_list_element_title:hover, {SELECTOR} .spm_product_list_element_title a:hover, {SELECTOR} a.spm_product_list_element_title:visited, '
                    + '{SELECTOR} .spm_product_list_element_title a:visited, {SELECTOR} a.spm_product_list_element_title:active, '
                    + '{SELECTOR} .spm_product_list_element_title a:active',
                  properties: [
                    {
                      name: 'font-family',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'font-size',
                      getter: getCssPropertyByName,
                      setters: [recalculateLineHeightValue, setCssProperty],
                    },
                    {
                      name: 'font-weight',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'font-style',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'text-decoration-line',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'text-transform',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'color',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.productTitleAlignment',
                  selector: '{SELECTOR} .spm_product_list_element_title',
                  properties: [{
                    name: 'text-align',
                    getter: getCssPropertyByName,
                    setters: [setCssProperty],
                  }],
                  ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
                },
                {
                  label: 'templateBuilder.fields.productTitleMarginVertical',
                  selector: '{SELECTOR} .spm_product_list_element_title',
                  properties: [
                    {
                      name: 'padding-top',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                    {
                      name: 'padding-bottom',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                  ],
                  ...getVerticalSpacesFieldConfig('padding'),
                },
                {
                  label: 'templateBuilder.fields.productTitleMarginHorizontal',
                  selector: '{SELECTOR} .spm_product_list_element_title',
                  properties: [
                    {
                      name: 'padding-left',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                    {
                      name: 'padding-right',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                  ],
                  ...getHorizontalSpacesFieldConfig('padding'),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.price',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} a.spm_product_list_element_price, {SELECTOR} .spm_product_list_element_price, {SELECTOR} .spm_product_list_element_price a',
                  properties: [
                    {
                      name: 'font-family',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'font-size',
                      getter: getCssPropertyByName,
                      setters: [recalculateLineHeightValue, setCssProperty],
                    },
                    {
                      name: 'font-weight',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'font-style',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'text-decoration-line',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'text-transform',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'color',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.productPriceMarginVertical',
                  selector: '{SELECTOR} table.spm_product_list_element_prices > tbody > tr > th, {SELECTOR} table.spm_product_list_element_prices > tbody > tr > td',
                  properties: [
                    {
                      name: 'padding-top',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                    {
                      name: 'padding-bottom',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                  ],
                  ...getVerticalSpacesFieldConfig('padding'),
                },
                {
                  label: 'templateBuilder.fields.productPriceMarginHorizontal',
                  selector: '{SELECTOR} table.spm_product_list_element_prices > tbody > tr > th, {SELECTOR} table.spm_product_list_element_prices > tbody > tr > td',
                  properties: [
                    {
                      name: 'padding-left',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                    {
                      name: 'padding-right',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                  ],
                  ...getHorizontalSpacesFieldConfig('padding'),
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.priceStrike',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} a.spm_product_list_element_price_strike, {SELECTOR} .spm_product_list_element_price_strike, '
                    + '{SELECTOR} .spm_product_list_element_price_strike a',
                  properties: [
                    {
                      name: 'font-family',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'font-size',
                      getter: getCssPropertyByName,
                      setters: [recalculateLineHeightValue, setCssProperty],
                    },
                    {
                      name: 'font-weight',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'font-style',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'text-decoration-line',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'text-transform',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'color',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                  ],
                  ...fontFieldConfig,
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.image',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.width',
                  selector: '{SELECTOR} img.spm_product_list_element_image',
                  properties: [{
                    name: 'max-width', getter: getCssPropertyByName, setters: [setSmartListImageWidth], callbacks: [refreshSmartProductList], refreshWidgetFields: true,
                  }],
                  ...getWidthFieldConfig(100),
                  options: {
                    px: {
                      min: 0,
                      max: 'PARENT_WIDTH',
                      step: 1,
                      defaultValue: 100,
                      isDefaultUnitSelected: false,
                    },
                  },
                },
                {
                  label: 'templateBuilder.fields.imageBorders',
                  selector: '{SELECTOR} img.spm_product_list_element_image',
                  properties: [
                    {
                      name: 'border-color',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'border-style',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-width',
                      getter: getCssPropertyByName,
                      setters: [setCssPropertyAndRemoveIfZero],
                    },
                    {
                      name: 'border-bottom-style',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'border-bottom-color',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-bottom-width',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-top-style',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'border-top-color',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-top-width',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-left-style',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'border-left-color',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-left-width',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-right-style',
                      getter: getCssPropertyByName,
                      setters: [setCssProperty],
                    },
                    {
                      name: 'border-right-color',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                    {
                      name: 'border-right-width',
                      getter: getCssPropertyByName,
                      setters: [],
                    },
                  ],
                  ...bordersFieldConfig,
                },
              ],
            },
            {
              type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
              label: 'templateBuilder.configs.productListProductStyles.button',
              selector: 'body #spm_body #{ID}',
              items: [
                {
                  label: 'templateBuilder.fields.buttonWidth',
                  selector: '{SELECTOR} table.spm_product_list_element_button',
                  properties: [{
                    name: 'width',
                    getter: getCssPropertyByName,
                    setters: [setCssButtonWidthProperty],
                  }],
                  ...getButtonWidthFieldConfig(),
                },
                {
                  label: 'templateBuilder.fields.buttonAlignment',
                  selector: '{SELECTOR} table.spm_product_list_element_button',
                  properties: [
                    {
                      name: 'align',
                      getter: getAttributeByName,
                      setters: [setSmartListButtonAlignment],
                      callbacks: [refreshSmartProductList],
                      refreshWidgetFields: true,
                    },
                  ],
                  ...getElementAlignFieldConfig('align'),
                },
                {
                  label: 'templateBuilder.fields.contours',
                  selector: '{SELECTOR} table.spm_product_list_element_button th',
                  properties: [{ name: 'border-radius', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getBorderRadiusFieldConfig('15px'),
                },
                {
                  label: 'templateBuilder.fields.buttonBorders',
                  selector: '{SELECTOR} table.spm_product_list_element_button th',
                  properties: [
                    { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-style', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
                    { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
                  ],
                  ...bordersFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonBackgroundColor',
                  selector: '{SELECTOR} table.spm_product_list_element_button th, {SELECTOR} table.spm_product_list_element_button:hover th, '
                    + '{SELECTOR} table.spm_product_list_element_button:active th, {SELECTOR} table.spm_product_list_element_button:visited th, '
                    + '{SELECTOR} table.spm_product_list_element_button th a, {SELECTOR} table.spm_product_list_element_button th a:hover, '
                    + '{SELECTOR} table.spm_product_list_element_button th a:active, {SELECTOR} table.spm_product_list_element_button th a:visited',
                  properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getColorFieldConfig('#ECECEC'),
                },
                {
                  label: 'templateBuilder.fields.font',
                  selector: '{SELECTOR} table.spm_product_list_element_button th, {SELECTOR} table.spm_product_list_element_button:hover th, '
                    + '{SELECTOR} table.spm_product_list_element_button:active th, {SELECTOR} table.spm_product_list_element_button:visited th, '
                    + '{SELECTOR} table.spm_product_list_element_button th a, {SELECTOR} table.spm_product_list_element_button th a:hover, '
                    + '{SELECTOR} table.spm_product_list_element_button th a:active, {SELECTOR} table.spm_product_list_element_button th a:visited',
                  properties: [
                    { name: 'font-family', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-size', getter: getCssPropertyByName, setters: [recalculateLineHeightValue, setCssProperty] },
                    { name: 'font-weight', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'font-style', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-decoration-line', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'text-transform', getter: getCssPropertyByName, setters: [setCssProperty] },
                    { name: 'color', getter: getCssPropertyByName, setters: [setCssProperty] },
                  ],
                  ...fontFieldConfig,
                },
                {
                  label: 'templateBuilder.fields.buttonContentAlignment',
                  selector: '{SELECTOR} table.spm_product_list_element_button th',
                  properties: [{ name: 'text-align', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getTextAlignFieldConfig(TextAlignValueEnum.CENTER),
                },
                {
                  label: 'templateBuilder.fields.buttonSpaceBetweenChars',
                  selector: '{SELECTOR} table.spm_product_list_element_button th, {SELECTOR} table.spm_product_list_element_button:hover th, '
                    + '{SELECTOR} table.spm_product_list_element_button:active th, {SELECTOR} table.spm_product_list_element_button:visited th, '
                    + '{SELECTOR} table.spm_product_list_element_button th a, {SELECTOR} table.spm_product_list_element_button th a:hover, '
                    + '{SELECTOR} table.spm_product_list_element_button th a:active, {SELECTOR} table.spm_product_list_element_button th a:visited',
                  properties: [{ name: 'letter-spacing', getter: getCssPropertyByName, setters: [setCssProperty] }],
                  ...getStepperFieldConfig(StepperSignEnum.PX),
                },
                {
                  label: 'templateBuilder.fields.spaceWithinButton',
                  selector: '{SELECTOR} table.spm_product_list_element_button th',
                  properties: [
                    { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                    { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
                  ],
                  componentName: ComponentNameEnum.SPACES,
                  options: {
                    ...getVerticalSpacesFieldConfig('padding').options,
                    ...getHorizontalSpacesFieldConfig('padding').options,
                  },
                },
              ],
            },
          ],
        },
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.background',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.backgroundColor',
            selector: '{SELECTOR}',
            properties: [{ name: 'background-color', getter: getCssPropertyByName, setters: [setCssProperty] }],
            ...getColorFieldConfig(),
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.marginsAndBorders',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.internalMargins',
            selector: '{SELECTOR} > tbody > tr > th',
            properties: [
              { name: 'padding-top', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-bottom', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-left', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'padding-right', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
            ],
            componentName: ComponentNameEnum.SPACES,
            options: {
              ...getVerticalSpacesFieldConfig('padding').options,
              ...getHorizontalSpacesFieldConfig('padding').options,
            },
          },
          {
            label: 'templateBuilder.fields.bordersCentralArea',
            selector: '{SELECTOR} > tbody > tr > th',
            properties: [
              { name: 'border-color', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-style', getter: getCssPropertyByName, setters: [] },
              { name: 'border-width', getter: getCssPropertyByName, setters: [setCssPropertyAndRemoveIfZero] },
              { name: 'border-bottom-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-bottom-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-bottom-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-top-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-top-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-left-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-left-width', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-style', getter: getCssPropertyByName, setters: [setCssProperty] },
              { name: 'border-right-color', getter: getCssPropertyByName, setters: [] },
              { name: 'border-right-width', getter: getCssPropertyByName, setters: [] },
            ],
            ...bordersFieldConfig,
          },
        ],
      },
      {
        type: ConfigurationPanelStructureItemEnum.FIELDS_GROUP,
        label: 'templateBuilder.configs.media',
        selector: 'body #spm_body #{ID}',
        items: [
          {
            label: 'templateBuilder.fields.mediaDisplay',
            selector: '{SELECTOR}',
            properties: [{ name: 'show-for-', getter: getClassByName, setters: [setMediaDisplay] }],
            ...mediaDisplayFieldConfig,
          },
        ],
      },
    ],
  },
};

export default widgetSmartProductListConfig;
