<template>
  <div
    class="grid col-12"
    style="text-align: center"
  >
    <div class="col-2" />
    <div class="col-8">
      <SpmTable
        v-model="tableData"
        :id-shop="idShop"
        :paginator="false"
        :show-grid-lines="true"
        :table-columns="columns"
        index="id"
        name=""
      >
        <template #fieldName="slotProps">
          <Dropdown
            :key="slotProps.data.id"
            v-model="fieldValue[slotProps.data.id]"
            :options="fieldOptions"
            option-label="name"
            option-value="value"
            style="width:100%"
          />
        </template>
      </SpmTable>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onBeforeMount, ref, SetupContext,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { SpmTableColumns, SpmTableState, StatsType } from '@/types';
import Dropdown from 'primevue/dropdown';
import SpmTable from '@/components/table/SpmTable.vue';
import { isValidEmail } from '@/helpers/Email';
import { UserState } from '@/composables/User';
import { store } from '@/store';
import { determineDelimiter } from '@/helpers/helpers';

interface RowMapping {
  id: number;
  value: string;
  fieldName: string;
  nextValue: string;
}

interface FieldOptions {
  name: string;
  value: string;
}

export default defineComponent({
  name: 'ColumnMappings',

  components: {
    Dropdown,
    SpmTable,
  },

  props: {
    dataString: {
      type: String,
      required: true,
    },

    dataArray: {
      type: Array,
      default: () => [],
    },
  },

  setup(props: { dataString: string; dataArray: Array<any> }) {
    const { t } = useI18n();
    const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);

    const dateFields = ref(['date_creation', 'birthday']);
    const phoneFields = ref(['phone2', 'phone1']);
    const stringFields = ref(['country', 'company', 'first_name', 'last_name']);
    const fieldOptions: FieldOptions[] = [
      {
        name: t('myLists.importContacts.assignment.exclude'),
        value: 'exclude',
      },
      {
        name: t('myLists.importContacts.assignment.id_shop_customer'),
        value: 'id_shop_customer',
      },
      {
        name: t('myLists.importContacts.assignment.last_name'),
        value: 'last_name',
      },
      {
        name: t('myLists.importContacts.assignment.first_name'),
        value: 'first_name',
      },
      {
        name: t('myLists.importContacts.assignment.email'),
        value: 'email',
      },
      {
        name: t('myLists.importContacts.assignment.gender'),
        value: 'gender',
      },
      {
        name: t('myLists.importContacts.assignment.birthday'),
        value: 'birthday',
      },
      {
        name: t('myLists.importContacts.assignment.date_creation'),
        value: 'date_creation',
      },
      {
        name: t('myLists.importContacts.assignment.lang'),
        value: 'lang',
      },
      {
        name: t('myLists.importContacts.assignment.opt_in'),
        value: 'newsletter',
      },
      {
        name: t('myLists.importContacts.assignment.active'),
        value: 'active',
      },
      {
        name: t('myLists.importContacts.assignment.phone1'),
        value: 'phone1',
      },
      {
        name: t('myLists.importContacts.assignment.phone2'),
        value: 'phone2',
      },
      {
        name: t('myLists.importContacts.assignment.company'),
        value: 'company',
      },
      {
        name: t('myLists.importContacts.assignment.address1'),
        value: 'address1',
      },
      {
        name: t('myLists.importContacts.assignment.address2'),
        value: 'address2',
      },
      {
        name: t('myLists.importContacts.assignment.postcode'),
        value: 'postcode',
      },
      {
        name: t('myLists.importContacts.assignment.city'),
        value: 'city',
      },
      {
        name: t('myLists.importContacts.assignment.country'),
        value: 'country',
      },
      {
        name: t('myLists.importContacts.assignment.region'),
        value: 'region',
      },
      {
        name: t('myLists.importContacts.assignment.custom1'),
        value: 'custom1',
      },
      {
        name: t('myLists.importContacts.assignment.custom2'),
        value: 'custom2',
      },
      {
        name: t('myLists.importContacts.assignment.custom3'),
        value: 'custom3',
      },
      {
        name: t('myLists.importContacts.assignment.custom4'),
        value: 'custom4',
      },
      {
        name: t('myLists.importContacts.assignment.other'),
        value: 'other',
      },
    ];

    const fieldValue = ref<string[]>([]);
    const tableData = ref<SpmTableState>({
      items: [],
      total: 0,
      error: '',
      selectedItems: [],
    });

    const getFieldName = (column: string) => {
      let fieldName: string | undefined = '';

      if (isValidEmail(column)) {
        fieldName = 'email';
      } else if (new RegExp('^\\d{4}-\\d{2}-\\d{2}(\\s?(([1-9]|0[1-9]|1[0-9]|2[0-4]):[0-5][0-9]:[0-5][0-9])?)$').test(column)) {
        fieldName = dateFields.value.pop();
        fieldName = fieldName ?? '';
      } else if (new RegExp('^[+]*[(]?[0-9]{1,3}[)]?[-\\s\\./0-9]*$').test(column)) {
        fieldName = phoneFields.value.pop();
        fieldName = fieldName ?? '';
      } else {
        fieldName = stringFields.value.pop();
        fieldName = fieldName ?? '';
      }

      return fieldName;
    };

    const columns: SpmTableColumns[] = [
      {
        field: 'id',
        header: '',
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
        hidden: true,
      },
      {
        field: 'value',
        header: t('myLists.importContacts.assignment.value'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'fieldName',
        header: t('myLists.importContacts.assignment.fieldName'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
      {
        field: 'nextValue',
        header: t('myLists.importContacts.assignment.nextValue'),
        sortable: false,
        filterable: false,
        editable: false,
        style: '',
        type: 'text',
      },
    ];

    onBeforeMount(() => {
      const rows: string[] = props.dataString.split('\n');

      let hasNextValue = rows.length > 1;

      const separator = determineDelimiter(rows);

      let columnsData: string[] = rows[0].split(separator);
      if (props.dataArray.length) {
        // eslint-disable-next-line prefer-destructuring
        columnsData = props.dataArray[0];
        hasNextValue = props.dataArray.length > 1;
      }

      let secondRow: string[] = [];

      if (rows.length > 1) {
        secondRow = rows[1].split(separator);
      }
      if (props.dataArray.length > 1) {
        // eslint-disable-next-line prefer-destructuring
        secondRow = props.dataArray[1];
      }

      const dataValue: RowMapping[] = [];
      columnsData.forEach((column, index) => {
        const fieldName = getFieldName(column);
        dataValue.push({
          id: index,
          value: column,
          fieldName,
          nextValue: hasNextValue ? secondRow[index] : '',
        });
        fieldValue.value[index] = fieldName;
      });

      store.commit('listImportEditor/setConfigData', {
        ...store.getters['listImportEditor/getConfigData'],
        columnsMapping: fieldValue.value,
      });

      tableData.value.items = dataValue;
      tableData.value.error = '';
      tableData.value.total = dataValue.length;
    });

    return {
      idShop,
      columns,
      fieldOptions,
      fieldValue,
      tableData,
    };
  },
});
</script>
