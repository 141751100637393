<template>
  <Loader
    v-if="isLoading"
    style="z-index:1102"
  />
  <div class="grid">
    <div class="col-12 flex flex-column">
      <div class="flex flex-column">
        <!-- Nouveau bouton pour ouvrir l'OverlayPanel -->
        <div
          class="alert-secondary mb-3"
        >
          <p v-html="t('templateBuilder.modals.AI.generateAutomaticContentHelp')"></p>
          <br><br>
          <Button
            type="button"
            :label="AIgenerateButtonLabel"
            icon="ai-layout-icon fa-light fa-wand-magic-sparkles"
            class="ai-layout active"
            :loading="AIResultInProgress"
            @click="toggleOverlay"
          />
          <br>
        </div>

        <!-- OverlayPanel contenant le champ URL et le bouton de soumission -->
        <OverlayPanel
          ref="overlayPanel"
          append-to="body"
        >
          <div class="flex flex-row gap-3">
            <div style="padding:1rem">
              <InputText
                v-model="url"
                class="mb-3"
                style="width: 100%;"
                :placeholder="t('templateBuilder.modals.AI.generateAutomaticContentFieldUrlPlaceholder')"
              />
              <div
                class="flex justify-content-end"
              >
                <SpmButton
                  :label="t('templateBuilder.modals.AI.generateButtonLabel')"
                  class="p-button p-button-success"
                  @click="submitUrl"
                />
              </div>
            </div>
          </div>
        </OverlayPanel>
        <div class="field">
          <label
            v-tooltip="t('storeParameters.services.aiServiceParameter.tooltips.styleContent')"
            for="styleContent"
          >
            {{ t('storeParameters.services.aiServiceParameter.fields.styleContent') }} <i class="fas fa-question-circle" />
          </label>
          <BaseTextarea
            id="styleContent"
            v-model="field.styleContent"
            :watch-props="true"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="error"
            field="styleContent"
          />
        </div>
        <div class="field">
          <label
            v-tooltip="t('storeParameters.services.aiServiceParameter.tooltips.businessDescription')"
            for="businessDescription"
          >
            {{ t('storeParameters.services.aiServiceParameter.fields.businessDescription') }} <i class="fas fa-question-circle" />
          </label>
          <BaseTextarea
            id="businessDescription"
            v-model="field.businessDescription"
            :watch-props="true"
          />
          <FieldErrors
            :key="componentFieldErrorsKey"
            :errors="error"
            field="businessDescription"
          />
        </div>
        <SpmButton
          v-if="!globalSave"
          :label="t('save')"
          :loading="saveLoading"
          class="p-button p-button-primary"
          @click="handleSave"
        />
      </div>
    </div>
  </div>
  <div
    v-if="globalSave"
    class="flex align-content-center justify-content-center align-items-center"
  >
    <slot name="save" />
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  defineExpose,
  ref,
  reactive,
  onMounted, ComputedRef, computed,
} from 'vue';

import Tooltip from 'primevue/tooltip';

import BaseTextarea from '@/components/fields/BaseTextarea.vue';
import FieldErrors from '@/components/fields/partials/FieldErrors.vue';
import SpmButton from '@/components/spm-primevue/SpmButton.vue';
import Button from 'primevue/button';
import Loader from '@/components/layout/Loader.vue';

import {
  getStyleParameter, MakeSPMAIRequest,
  setStyleParameter,
} from '@/composables/services/ShopimindAI';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { ErrorConfigForm } from '@/types/automated-scenarios';
import { showToastError, showToastSuccess } from '@/helpers';

import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';

import { useI18n } from 'vue-i18n';
import axios from 'axios';
import sha1 from "sha1";
import {UserState} from "@/composables/User";

export default defineComponent({
  name: 'ImportantLinksServiceParameter',

  components: {
    BaseTextarea,
    FieldErrors,
    SpmButton,
    Button,
    Loader,
    OverlayPanel,
    InputText,
  },

  directives: {
    tooltip: Tooltip,
  },

  props: {
    shopId: {
      type: Number,
      required: true,
    },

    globalSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const { t } = useI18n();

    const isLoading = ref(false);
    const saveLoading = ref(false);

    const field = reactive<Record<string, string>>({
      styleContent: '',
      businessDescription: '',
    });

    const componentFieldErrorsKey = ref(0);
    const error = ref();
    const overlayPanel = ref<OverlayPanel | null>(null);
    const url = ref('');
    const AIResultInProgress = ref(false);
    const AIgenerateButtonLabel = ref('');
    AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateAutomaticContentButtonLabel');
    const toggleOverlay = (event: MouseEvent) => {
      overlayPanel.value?.toggle(event);
    };
    const idShop: ComputedRef<number> = computed(() => (UserState.activeShop ? UserState.activeShop.id : 0));
    const validateUrl = (url: string) => {
      const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' // protocol
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
        + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
        + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
        + '(\\#[-a-z\\d_]*)?$', 'i', // fragment locator
      );
      return !!urlPattern.test(url);
    };

    const submitUrl = async () => {
      if (!validateUrl(url.value)) {
        showToastError(t('templateBuilder.modals.AI.generateAutomaticContentInvalidUrl'));
        return;
      }
      // Fermer l'OverlayPanel après soumission réussie
      // eslint-disable-next-line no-unused-expressions
      overlayPanel.value?.hide();
      AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateWaitButtonLabel');
      AIResultInProgress.value = true;
      try {
        const response = await MakeSPMAIRequest(`/text-generator/get-ai-parameters-from-url/${idShop.value}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop.value}`)}`, {
          url: url.value,
          lang: 'fr',
        });

        if (response.data.success) {
          field.styleContent = response.data.result.content.editorial_style;
          field.businessDescription = response.data.result.content.business_description;
          showToastSuccess(t('templateBuilder.modals.AI.generateAutomaticContentSuccess'));
          AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateButtonLabel');
          AIResultInProgress.value = false;
        } else {
          showToastError(t('templateBuilder.modals.AI.generateAutomaticContentError'));
          AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateButtonLabel');
          AIResultInProgress.value = false;
        }
        // eslint-disable-next-line no-shadow
      } catch (error) {
        showToastError(t('templateBuilder.modals.AI.generateAutomaticContentError'));
        AIgenerateButtonLabel.value = t('templateBuilder.modals.AI.generateButtonLabel');
        AIResultInProgress.value = false;
      }
    };

    const validate = async (): Promise<ErrorConfigForm> => {
      const rules: Record<string, any> = {
        styleContent: {
          required,
        },

        businessDescription: {
          required,
        },
      };

      const v$ = useVuelidate(rules, field);
      const success = await v$.value.$validate();

      return {
        success,
        validate: v$,
      };
    };

    const saveParameter = async () => {
      await setStyleParameter(props.shopId, 'editorial-style-content', { content: field.styleContent });
      await setStyleParameter(props.shopId, 'business-description', { description: field.businessDescription });
    };

    // eslint-disable-next-line consistent-return
    const handleSave = async () => {
      const validation = await validate();

      let validationErr = 0;

      if (!validation.success) {
        error.value = validation.validate;
        componentFieldErrorsKey.value += 1;
        validationErr += validation.validate.value.$errors.length;
        return {
          err: validation.validate.value.$errors.length,
          configs: [],
        };
      }

      if (props.globalSave) {
        return {
          err: null,
          configs: [],
          additionnalSave: saveParameter,
        };
      }

      saveLoading.value = true;

      try {
        await saveParameter();
        if (!props.globalSave) {
          showToastSuccess('savedSuccessful');
        }
      } catch (err) {
        if (!props.globalSave) {
          showToastError('GENERIC_ERROR');
        } else {
          throw err;
        }
      } finally {
        saveLoading.value = false;
      }
    };

    defineExpose({
      handleSave,
    });

    onMounted(async () => {
      try {
        isLoading.value = true;
        const editorialStyle = await getStyleParameter(props.shopId, 'editorial-style-content');
        const businessDescription = await getStyleParameter(props.shopId, 'business-description');
        if (editorialStyle && editorialStyle.data && editorialStyle.data.result) {
          field.styleContent = editorialStyle.data.result;
        }
        if (businessDescription && businessDescription.data && businessDescription.data.result) {
          field.businessDescription = businessDescription.data.result;
        }
      } catch (err) {
        showToastError('GENERIC_ERROR');
      } finally {
        isLoading.value = false;
      }
    });

    return {
      t,
      isLoading,
      saveLoading,
      field,
      componentFieldErrorsKey,
      error,
      url,
      overlayPanel,
      toggleOverlay,
      submitUrl,
      handleSave,
      AIgenerateButtonLabel,
      AIResultInProgress,
    };
  },
});
</script>

<style lang="scss" scoped>
.ai-layout {
  width: auto;
  border: 3px solid $solitude;
  color: $columnsWidgetLayoutColor;
  border-radius: 10px;
  padding: 15px 10px;
  cursor: pointer;
  .ai-layout-icon {
    font-size:25px;
  }
  &:hover {
    border: 3px solid transparent!important;
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(167deg, rgba(148,200,64,1) 0%, rgba(0,153,120,1) 50%, rgba(0,114,142,1) 100%) border-box!important;
    color:#009978!important;
  }

  &.active {
    border: 3px solid transparent;
    background: linear-gradient(#fff, #fff) padding-box, linear-gradient(167deg, rgba(148,200,64,1) 0%, rgba(0,153,120,1) 50%, rgba(0,114,142,1) 100%) border-box;
    color:#009978;
  }
}
</style>
