<template>
  <div
    v-tooltip="{ value: t('segmentations.promptAI.prompt_tooltip'), class: 'tooltip-icons-outside'}"
    class="prompt-ai-container"
  >
    <div class="prompt-ai-content">
      <label
        class="justify-content-center align-items-center"
      >
        <i
          class="far fa-light fa-microchip-ai ia-icon"
          title="Prompt IA"
        />&nbsp;
        <span>
          {{ t('segmentations.promptAI.prompt_title') }}
        </span>
        <span
          class="prompt-ai-help-link"
          v-html="t('segmentations.promptAI.help_link')"
        >
        </span>
      </label>
      <Textarea
        v-model="promptText"
        rows="3"
        cols="30"
        class="prompt-ai-textarea"
        :placeholder="t('segmentations.promptAI.textarea_placeholder')"
        auto-resize
      />
    </div>
    <div class="prompt-ai-footer">
      <div class="prompt-ai-footer-start">
        <template v-if="showFeedbackIcons">
          <i
            v-tooltip="{ value: t('segmentations.promptAI.prompt_like') }"
            class="fa-solid fa-thumbs-up feedback-icon like"
            :class="{ active: feedback === 'like' }"
            @click="setFeedback('like')"
          />
          <i
            v-tooltip="{ value: t('segmentations.promptAI.prompt_dislike') }"
            class="fa-solid fa-thumbs-down feedback-icon dislike"
            :class="{ active: feedback === 'dislike' }"
            @click="setFeedback('dislike')"
          />
        </template>
      </div>
      <div
        class="prompt-ai-footer-end"
        :class="{ 'has-not-correct-offer': !hasCorrectOffer }"
      >
        <p v-if="!hasCorrectOffer">
          {{ t('templateBuilder.modals.AI.offerUpgrade') }}<br><br>
          <Button
            :label="t('offers.updatePlanButton')"
            icon="far fa-arrow-right"
            class="p-button p-button-secondary"
            icon-pos="right"
            @click="updatePlan"
          />
        </p>
        <Button
          v-else
          type="button"
          :label="t('segmentations.promptAI.button')"
          icon="fa-light fa-wand-magic-sparkles"
          class="ai-layout-global active"
          :loading="isLoadingGeneration"
          :disabled="promptText.length === 0"
          @click="generateConfig"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent, onMounted, ref, watch,
} from 'vue';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { useI18n } from 'vue-i18n';
import Tooltip from 'primevue/tooltip';
import axios from 'axios';
import { MakeSPMAIRequest } from '@/composables/services/ShopimindAI';
import sha1 from 'sha1';
import { UserState } from '@/composables/User';
import moment from 'moment';
import { useStore } from '@/store';
import { addUrlParameter, sendMessageToSlack } from '@/helpers/helpers';

export default defineComponent({
  name: 'SegmentationPromptAI',

  components: {
    Textarea,
    Button,
  },

  directives: {
    tooltip: Tooltip,
  },

  emits: ['generate-config', 'generate-config-in-progress'],

  setup(_, { emit }) {
    const { t } = useI18n();
    const idShop = ref(UserState?.activeShop?.id ?? 0);
    const hasCorrectOffer = ref(false);
    const store = useStore();

    const promptText = ref('');
    const isLoadingGeneration = ref(false);
    const feedback = ref<string | null>(null);
    const showFeedbackIcons = ref(false);
    const lastAIResponse: any = ref({
      id: null,
      question: null,
      response: null,
    });
    const lastFeedbackID = ref(null);

    const setFeedback = (type: 'like' | 'dislike') => {
      if (lastAIResponse.value.id === lastFeedbackID.value) {
        return;
      }
      feedback.value = type;
      lastFeedbackID.value = lastAIResponse.value.id;

      if (type === 'like') {
        sendMessageToSlack('B08GK97AQG2/AE14VoG8pFEukgLUhp45xQj4', `Id Shop : ${idShop.value}\nPrompt: ${lastAIResponse.value.question}`, lastAIResponse.value.response);
      } else {
        sendMessageToSlack('B08GMRMKMFW/KA4xXavygvmGsIokhzqHtcME', `Id Shop : ${idShop.value}\nPrompt: ${lastAIResponse.value.question}`, lastAIResponse.value.response);
      }
      // TODO : call API to give feedback
    };

    const generateConfig = async () => {
      emit('generate-config-in-progress');
      isLoadingGeneration.value = true;
      feedback.value = null;
      showFeedbackIcons.value = false;

      // sleep 2 seconds for test loading
      // TODO : call API to generate config
      const aiRequestPromise = await MakeSPMAIRequest(
        `config-generator/get-segmentation-from-prompt/${idShop.value}/${sha1(`${process.env.VUE_APP_SPMAI_API_SALT}${idShop.value}`)}`,
        {
          prompt: promptText.value,
        },
      );
      const configGen = aiRequestPromise?.data?.result?.content ?? null;

      let success = true;
      let generatedConfig: any = null;
      if (!configGen || configGen === 'FALSE') {
        success = false;
      } else {
        generatedConfig = JSON.parse(configGen);
      }

      if (success) {
        try {
          const result = await axios.post(
            `${process.env.VUE_APP_NESTJS_SEGMENTATION_API_URL}/validate`,
            {
              idShop: idShop.value,
              config: generatedConfig,
            },
          );
          if (!result || (result.status !== 200 && result.status !== 201)) {
            success = false;
          }
        } catch (error) {
          success = false;
        }
      }

      isLoadingGeneration.value = false;
      emit('generate-config', {
        success,
        config: generatedConfig,
      });
      if (success) {
        sendMessageToSlack('B08GG4UCJNR/9RrQf4UmVikP0wAYhUN1Yn1y', `Id Shop : ${idShop.value}\nPrompt: ${promptText.value}`, generatedConfig);
        lastAIResponse.value = {
          id: Math.random(),
          question: promptText.value,
          response: generatedConfig,
        };
        showFeedbackIcons.value = true;
        // eslint-disable-next-line no-empty
      } else {
        sendMessageToSlack('B08H7DEGTCY/SPDhnZIoUBSoOUxnVGtfZobC', `Id Shop : ${idShop.value}\nPrompt: ${promptText.value}`, generatedConfig);
      }
    };

    const watchUserState = () => {
      if (UserState.activeOffer) {
        if (UserState.activeOffer.trialPlan) {
          if (UserState.activeOffer.trialPlanLimit) {
            hasCorrectOffer.value = moment().isBefore(moment(UserState.activeOffer.trialPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasCorrectOffer.value = true;
          }
        } else if (UserState.activeOffer.forcedPlan) {
          if (UserState.activeOffer.forcedPlanLimit) {
            hasCorrectOffer.value = moment().isBefore(moment(UserState.activeOffer.forcedPlanLimit, 'YYYY-MM-DD'));
          } else {
            hasCorrectOffer.value = true;
          }
        } else if (UserState.activeOffer.currentPlan) {
          hasCorrectOffer.value = true;
        } else {
          hasCorrectOffer.value = false;
        }
      } else if (!UserState.activeOffer && UserState.hasOfferV3) {
        hasCorrectOffer.value = false;
      }
    };

    const updatePlan = () => {
      store.commit('choosePlan/setIsVisible', true);
    };

    watch(() => UserState.activeOffer, () => {
      watchUserState();
    }, { deep: true });

    onMounted(() => {
      watchUserState();
      // Ajouter un paramètre à l'URL afin de pouvoir charger gleap via url
      addUrlParameter('cp', 'perfectsegmentai');
    });

    return {
      t,
      updatePlan,
      promptText,
      generateConfig,
      isLoadingGeneration,
      feedback,
      setFeedback,
      showFeedbackIcons,
      hasCorrectOffer,
    };
  },
});
</script>

<style scoped lang="scss">
.prompt-ai-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  border-radius: 12px;
  border: 1px solid transparent;
  background:
    linear-gradient(#fff, #fff) padding-box,
    linear-gradient(167deg, #94c840, #009978 50%, #00728e) border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.ia-icon {
  color: #00728e;
  font-size: 1.2rem;
  cursor: default;
  transition: 0.3s ease-in-out;
}

.ia-icon:hover {
  color: #009978;
}

.prompt-ai-content {
  padding: 12px;
  background: white;
  border-radius: 12px 12px 0 0;
}

.prompt-ai-textarea {
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  color: #333;
  resize: none;
  background: transparent;
  overflow-y: hidden;
  min-height: 40px;
  /* Réserve un espace à droite pour l'icône */
  padding: 0 40px 0 0;
  white-space: pre-wrap; /* Permet au texte de revenir à la ligne */
  word-wrap: break-word; /* Évite les débordements */
  margin: 0.75rem 0 0;
}

.prompt-ai-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 12px 12px;

  .prompt-ai-footer-start {
    flex: 1;
  }
  .prompt-ai-footer-end {
    flex: 2;
    text-align: right;
  }
}

.feedback-icon {
  font-size: 1.2rem;
  margin-left: 10px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  color: #aaa;
}

.feedback-icon.like:hover,
.feedback-icon.like.active {
  color: #009978;
}

.feedback-icon.dislike:hover,
.feedback-icon.dislike.active {
  color: #d9534f;
}
.prompt-ai-help-link {float:right;font-size: 0.9rem;}
</style>
