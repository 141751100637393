import { ListImportEditorState, State } from '@/types/store-types';
import {
  ManualListImportConfigData, ManualListImportSettingsData, SegmentationListImportConfigData, SegmentationListImportSettingsData,
} from '@/types/lists';
import { ActionContext } from 'vuex';
import {
  clearVariables,
  insertShopsListsImport,
} from '@/composables/mes-listes/ImportCustomers';
import { ref } from 'vue';
import { listManageKey, myListMod, myListsState } from '@/composables/mes-listes/ListsManage';
import { UserState } from '@/composables/User';
import { ShopsListsImportInputItem, ShopsListsInputItem } from '@/types/generated-types/graphql';
import { showToastError, showToastSuccess } from '@/helpers';
import { queueState, ListType } from '@/types';
import { i18n } from '@/i18n';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { InsertShopsLists } from '@/composables/shop/ShopsLists';

let translation: any;
(async () => {
  translation = await i18n;
})();

const getDefaultSettingsData = (type: string): ManualListImportSettingsData | SegmentationListImportSettingsData => {
  if (type === 'segmentation') {
    return {
      keepListUpdated: false,
      selectedCustomerLanguages: [],
      selectedTargetedLists: [],
    };
  }

  return {
    isDoubleOptIn: false,
    updateExistingCustomers: false,
  };
};

const getDefaultConfigData = (type: string): ManualListImportConfigData | SegmentationListImportConfigData => {
  if (type === 'segmentation') {
    return {
      keepListUpdated: false,
      selectedCustomerLanguages: [],
      selectedTargetedLists: [],
    };
  }

  return {
    columnsMapping: [],
    dataString: '',
    dataArray: [],
    separator: ',',
    slicedDataArray: [],
  };
};

export default {
  namespaced: true,
  state: (): ListImportEditorState => ({
    isEditorVisible: false,
    listName: '',
    idShopList: 0,
    idShopListImport: 0,
    typeImport: '',
    settingsData: getDefaultSettingsData('manual'),
    configData: getDefaultConfigData('manual'),
    isSettingPanelVisible: false,
    listType: '',
  }),

  getters: {
    getIsEditorVisible: (state: ListImportEditorState) => state.isEditorVisible,
    getListName: (state: ListImportEditorState) => state.listName,
    getTypeImport: (state: ListImportEditorState) => state.typeImport,
    getIdShopList: (state: ListImportEditorState) => state.idShopList,
    getIdShopListImport: (state: ListImportEditorState) => state.idShopListImport,
    getSettingsData: (state: ListImportEditorState) => state.settingsData,
    getConfigData: (state: ListImportEditorState) => state.configData,
    getIsSettingPanelVisible: (state: ListImportEditorState) => state.isSettingPanelVisible,
    getListType: (state: ListImportEditorState) => state.listType,
  },

  mutations: {
    resetState: (state: ListImportEditorState) => {
      state.isEditorVisible = false;
      state.listName = '';
      state.idShopList = 0;
      state.idShopListImport = 0;
      state.typeImport = '';
      state.settingsData = getDefaultSettingsData('manual');
      state.configData = getDefaultConfigData('manual');
      state.listType = '';
    },
    setIsEditorVisible: (state: ListImportEditorState, visible: boolean) => {
      state.isEditorVisible = visible;
    },
    setListName: (state: ListImportEditorState, listName: string) => {
      state.listName = listName;
    },
    setTypeImport: (state: ListImportEditorState, typeImport: string) => {
      state.typeImport = typeImport;
    },
    setIdShopList: (state: ListImportEditorState, idShopList: number) => {
      state.idShopList = idShopList;
    },
    setIdShopListImport: (state: ListImportEditorState, idShopListImport: number) => {
      state.idShopListImport = idShopListImport;
    },
    setSettingsData: (state: ListImportEditorState, payload: ManualListImportSettingsData | SegmentationListImportSettingsData) => {
      state.settingsData = payload;
    },
    setConfigData: (state: ListImportEditorState, payload: ManualListImportConfigData | SegmentationListImportConfigData) => {
      state.configData = payload;
    },
    setIsSettingPanelVisible: (state: ListImportEditorState, payload: boolean) => {
      state.isSettingPanelVisible = payload;
    },
    setKeepUpdated: (state: ListImportEditorState, payload: boolean) => {
      if ('keepListUpdated' in state.settingsData) {
        state.settingsData.keepListUpdated = payload;
      }
    },
    setListType: (state: ListImportEditorState, listType: string) => {
      state.listType = listType;
    },
  },

  actions: {
    importCustomersAction: async ({ state, commit }: ActionContext<ListImportEditorState, State>,
      {
        idShopList, typeImport, listName, idShopListImport, listType
      }: { idShopList: number; typeImport: string; listName: string; idShopListImport: number; listType: string }) => {
      const idShop = ref(UserState.activeShop ? UserState.activeShop.id : 0);
      // set the state
      commit('resetState');
      commit('setIdShopList', idShopList);
      commit('setTypeImport', typeImport);
      commit('setListName', listName);
      commit('setIdShopListImport', idShopListImport);
      commit('setSettingsData', getDefaultSettingsData(typeImport));
      commit('setConfigData', getDefaultConfigData(typeImport));
      commit('setListType', listType);
      // show the editor
      commit('setIsEditorVisible', true);
    },

    saveImportCustomersManualAction: async ({ state, commit }: ActionContext<ListImportEditorState, State>,
      { idShop }: { idShop: number }) => {
      let numberOfExcludedColumns = 0;
      const params = JSON.stringify((state.configData as ManualListImportConfigData).columnsMapping);
      (state.configData as ManualListImportConfigData).columnsMapping.forEach((column: any) => {
        if (column === 'exclude') {
          numberOfExcludedColumns += 1;
        }
      });

      if ((state.configData as ManualListImportConfigData).columnsMapping.length === numberOfExcludedColumns) {
        await showToastError(translation.global.t('myLists.importContacts.errors.invalidMapping'));
        return;
      }

      let newFileArray;

      const { dataString, separator, dataArray } = state.configData as ManualListImportConfigData;

      if (dataString) {
        const newDataArray = dataString.split('\n');
        newFileArray = newDataArray.map((row: string) => row.split(separator));
      } else if (dataArray.length) {
        newFileArray = JSON.parse(JSON.stringify(dataArray));
      }
      const input: ShopsListsImportInputItem = {
        id_shop: idShop,
        id_shop_list: state.idShopList,
        params,
        file: JSON.stringify(newFileArray),
        segments_config: '',
        force_update: (state.settingsData as ManualListImportSettingsData).updateExistingCustomers ? 1 : 0,
        newsletter: 1,
        double_optin: (state.settingsData as ManualListImportSettingsData).isDoubleOptIn,
        stay_updated: false,
        nb_customers: 0,
        nb_customers_checked: 0,
        nb_customers_valid: 0,
        nb_imported_customers: 0,
        nb_non_imported_customers: 0,
        nb_partially_imported_customers: 0,
        path_report_folder: '',
        state: queueState.QUEUED,
      };

      const result = await insertShopsListsImport([input]);

      if (result.err !== '') {
        await showToastError(result.err);
        return;
      }

      showToastSuccess(translation.global.t('myLists.importContacts.segmentation.insertNewListManual'))
        .then(() => {
          commit('setIsEditorVisible', false);
          clearVariables();
          listManageKey.value += 1;
          myListMod.value.ID = state.idShopList;
          myListMod.value.Name = state.listName;
          myListMod.value.HardBounce = 0;
          myListMod.value.NonSubscribers = 0;
          myListMod.value.PercentHardBounce = '';
          myListMod.value.PercentNonSubscribers = '';
          myListMod.value.PercentSoftBounce = '';
          myListMod.value.PercentSpams = '';
          myListMod.value.PercentSubscribers = '';
          myListMod.value.PercentUnSubscribers = '';
          myListMod.value.SoftBounce = 0;
          myListMod.value.Spams = 0;
          myListMod.value.Subscribers = 0;
          myListMod.value.UnSubscribers = 0;
          myListMod.value.TotalContacts = 0;
          myListMod.value.TotalSent = 0;
          myListMod.value.TotalUniqueViews = 0;
          myListMod.value.PercentUniqueViews = '0';
          myListMod.value.TotalUniqueClicks = 0;
          myListMod.value.PercentUniqueClicks = '0%';

          myListsState.refreshComponent = state.idShopList;
          commit('resetState');
        });
    },

    saveNoImportAction: async ({ state, commit }: ActionContext<ListImportEditorState, State>,
      // eslint-disable-next-line consistent-return
      { idShop, listName, returnResult }: { idShop: number; listName: string; returnResult?: boolean }) => {
      const newShopLists: ShopsListsInputItem = {
        id_shop: idShop,
        name: listName,
        total_contacts: 0,
        total_subscribers: 0,
        total_unsubscribeds: 0,
        total_sends: 0,
        total_sends_soft_bounce: 0,
        total_sends_hard_bounce: 0,
        total_sends_spam: 0,
        total_newsletters_viewed: 0,
        total_newsletters_clicked: 0,
        date_add: new Date(),
        date_modification: new Date(),
        active: 1,
        rating: 0,
        valid: true,
        whitelist: false,
        type: ListType.LIST,
      };

      const result = await InsertShopsLists([newShopLists]);
      if (result.err !== '') {
        await showToastError(result.err);
        return;
      }

      if (returnResult) {
        // eslint-disable-next-line consistent-return
        return result;
      }

      await showToastSuccess(translation.global.t('myLists.importContacts.segmentation.insertNewListManual'))
        .then(() => {
          listManageKey.value += 1;
          myListsState.refreshComponent = state.idShopList;
          commit('resetState');
        });
    },
  },
};
